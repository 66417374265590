import React from "react";
import { Link } from "react-router-dom";
import img1 from "./BWT-102/Images/cartridge-collecting-unit-1024x590.png"
import img2 from "./BWT-250/Images/BWT_250-Bullet_recovery_water_tank.png"
import img3 from "./Mobile Bullet Recovery Center/Images/Mobile-Bullet-Recovery-Center.png"

export default function Buttle() {
    return (
        <div className="w-full xl:pt-28">
            <div className="w-full items-content justify-center flex bg-[#7a1548] py-7">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 flex">
                    <div className="w-1/2">
                        <span className="text-white text-xl">Bullet Recovery System</span>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <div className="text-white text-xs">
                            <a href="/">Home</a><span> > </span><span>Firearms & Tool Marks > Bullet Recovery System</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full items-content justify-center flex mb-5">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 py-12 items-content justify-center">
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img1} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">BWT-102</span><br /><br />
                                <a href="/firearms-tool-marks/Bullet Recovery System/BWT-102" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img2} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">BWT-250</span><br /><br />
                                <a href="/firearms-tool-marks/Bullet Recovery System/BWT-250" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img3} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Mobile Bullet Recovery Center</span><br /><br />
                                <a href="/firearms-tool-marks/Bullet Recovery System/Mobile-Bullet-Recovery-Center" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    )
}