import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className="text-lg text-gray-500">Embedded Document Reader</h2>
                    <h3 className="text-lg text-gray-500">Document Reader Regula 70X8M</h3>
                    <p className="text-lg text-gray-500">The Regula 70X8M is an embeddable full-page document reader designed for automatic border security kiosks, e-gates, check-in counters, and more.</p>

                    <h4 className="text-lg text-gray-500">Document Verification for a Seamless Customer Journey</h4>
                    <p className="text-lg text-gray-500">With the Regula 70X8M, you can enhance user experience at banks, airports, government offices, and other places by enabling self-service registrations and reducing administrative workload. The device integrates intelligence and convenience into obligatory procedures.</p>

                    <h4 className="text-lg text-gray-500">Technologies Used</h4>
                    <ul>
                        <li><strong>Automatic document type identification:</strong> Verify data from 14,000+ international documents using AI and ML.</li>
                        <li><strong>Optical Character Recognition (OCR):</strong> Convert typed or printed text into digital data.</li>
                        <li><strong>MRZ reading:</strong> Scan and cross-check data from machine-readable zones.</li>
                        <li><strong>RFID reading:</strong> Verify documents equipped with Radio Frequency Identification (RFID) chips.</li>
                        <li><strong>Processing graphic fields:</strong> Automatically search and crop signature, fingerprint, and document holder’s portrait to display as separate images.</li>
                        <li><strong>Barcode reading:</strong> Recognize all kinds of barcodes to accelerate operations.</li>
                        <li><strong>Document authenticity:</strong> Automatic authenticity verification of documents.</li>
                        <li><strong>Smart UV:</strong> Compensation for external light during image capture in ultraviolet light.</li>
                        <li><strong>Elimination of glare:</strong> Remove reflections from laminate and holograms in white and IR light.</li>
                    </ul>

                    <h4 className="text-lg text-gray-500">Capabilities That Look and Feel Native</h4>
                    <p className="text-lg text-gray-500">The Regula 70X8M smoothly integrates into any front desk system, transforming it into an advanced automated checkpoint. Its AI-based algorithms scan and verify passports, IDs, visas, driver’s licenses, and all kinds of identity documents from any country.</p>

                    <p className="text-lg text-gray-500">Operating it is simple and requires no additional steps. The 70X8M detects when a document is placed in the scanning zone and automatically begins processing it.</p>

                    <h4 className="text-lg text-gray-500">Device Construction and Features</h4>
                    <ul>
                        <li>The construction features a hard plastic body with a top made of glass, which can be replaced with scratch-resistant glass if needed.</li>
                        <li>The scanning area accommodates a double-page spread of a passport.</li>
                        <li>Advanced OCR technology eliminates the need for manual data entry.</li>
                        <li>Includes an SDK for seamless synchronization of gathered data with your systems.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}