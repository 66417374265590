import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochure/Spectra Check Brochure 2024 EN for web.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className='text-gray-500'>Spectra Check</h2>
                    <p className='text-gray-500'><strong>The all-in-one device for quick and easy results</strong></p>
                    <p className='text-gray-500'>Our innovative device is designed for police, border control, green borders, seaports, airports, immigration authorities, insurance companies, banks, legal offices, and local governments. It offers advanced solutions for verifying passports, IDs, banknotes, property and land certificates, breeder documents, contracts, wills/testaments, or any other security-relevant documents.</p>

                    <h3 className='text-gray-500'>Key Features</h3>
                    <ul className='text-gray-500'>
                        <li>14" 4K touchscreen with anti-glare glass</li>
                        <li>4 MP camera with fast responding autofocus</li>
                        <li>Magnification up to 240x</li>
                        <li>15 integrated light sources, all LED lighting</li>
                        <li>Integrated passport & ID reader</li>
                        <li>Automated face recognition</li>
                        <li>Automated examination routines</li>
                        <li>Internet connectivity</li>
                        <li>Instantly ready to use at the touch of your finger</li>
                        <li>Device opens on 3 sides for larger documents</li>
                    </ul>

                    <h3 className='text-gray-500'>Further Details</h3>

                    <h4 className='text-gray-500'>Technical Specifications</h4>
                    <ul className='text-gray-500'>
                        <li>Weight: 14 kg (approx.)</li>
                        <li>Dimensions: 322 mm x 396 mm x 448 mm (W x D x H)</li>
                        <li>Optical magnification: up to 240x</li>
                        <li>Max Field of View: 222 mm x 125 mm</li>
                    </ul>

                    <h4>Software Features (Built-in as Standard)</h4>
                    <ul>
                        <li>Automated examination tools</li>
                        <li>Annotations and measurements</li>
                        <li>Online reference database</li>
                        <li>Comparison tools</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='text-gray-500'>
                       


                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}