import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className="text-lg text-gray-500">Compact Passport Reader for Essential Verification</h2>
                    <h3 className="text-lg text-gray-500">Document Reader Regula 70X7</h3>
                    <p className="text-lg text-gray-500">The Regula 70X7 automatically scans and verifies a comprehensive range of identity documents from around the world, including passports, driver’s licenses, ID cards, visas, immigration cards, and many others.</p>

                    <h4 className="text-lg text-gray-500">Compact Yet Powerful</h4>
                    <p className="text-lg text-gray-500">The Regula Compact Identity Document Reader enables automatic data capture and identity verification in banks, hospitals, hotels, telecom companies, retailers, education centers, and many other organizations. Within mere seconds, identity document data is automatically and accurately filled in.</p>

                    <p className="text-lg text-gray-500">Regula’s in-house software, delivered with the device, efficiently extracts data from visual and machine-readable zones, processes data from RFID chips, reads barcodes, and cross-compares the obtained data to detect fraud. Its flexible and customizable design ensures smooth integration with third-party systems.</p>

                    <h4 className="text-lg text-gray-500">Technologies Used</h4>
                    <ul className="text-lg text-gray-500">
                        <li><strong>Automatic document type identification:</strong> Verify data from 12,000+ international documents.</li>
                        <li><strong>Optical Character Recognition (OCR):</strong> Convert typed or printed text into digital data.</li>
                        <li><strong>MRZ reading:</strong> Scan and cross-check data from machine-readable zones.</li>
                        <li><strong>RFID reading:</strong> Verify documents equipped with Radio Frequency Identification (RFID) chips.</li>
                        <li><strong>Graphic fields processing:</strong> Automatically search and crop signature, fingerprint, and document holder’s portrait to display as separate images.</li>
                        <li><strong>Barcode reading:</strong> Recognize all kinds of barcodes to accelerate operations.</li>
                    </ul>

                    <h4 className="text-lg text-gray-500">Automated Identity Verification Process</h4>
                    <ol>
                        <li>The optical reader automatically detects a document in the scanning area of the device.</li>
                        <li>Document images are captured in different illumination modes.</li>
                        <li>Regula Document Reader SDK processes data and recognizes the document type.</li>
                        <li>The software performs document processing according to the document template.</li>
                        <li>The results of the document verification are ready for further use.</li>
                        <li>The multicolor LED indicator on the device shows the processing status.</li>
                    </ol>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}