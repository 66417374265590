import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/CL-X.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® X</h2>

                    <p class="mb-4">
                        The Crime-lite® X is a handheld, multi-spectral LED light source that represents a significant advancement in forensic light source technology. This all-in-one ALS kit allows examiners to seamlessly switch between a variety of wavelengths to conduct thorough examinations at crime scenes and in laboratories.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Comprehensive Multi-Spectral Examination:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Operates with White, UV, Violet, Blue, Blue/Green, Green, Orange, Red, and Infrared LEDs.</li>
                        <li>Combine any 3 LEDs to multiply output power, generating up to 175 wavelength combinations.</li>
                    </ul>

                  

                    <h3 class="font-semibold text-lg mb-2">Get Connected:</h3>
                    <p class="mb-4">
                        The dedicated Crime-lite X mobile app allows users to operate and monitor a single light source or group multiple units for various applications.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Applications:</h3>
                    <p class="mb-4">
                        The Crime-lite X provides up to 175 wavelength combinations for intense illumination, aiding in the detection and examination of various physical evidence types, including:
                    </p>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Fingerprint in Engine Grease</li>
                        <li>Fingerprint treated with BY-40</li>
                        <li>Fingerprint treated with DFO</li>
                        <li>Fingerprint dusted with fpNatural</li>
                        <li>Semen on Dark Fabrics</li>
                        <li>IR Light/IR Pass filters</li>
                        <li>Accelerants (Petrol) on White Fabrics</li>
                        <li>Gunshot Residue on Black Footwear</li>
                        <li>Body Fluids on Carpet</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Crime-lite® X Serology Search Kit:</h3>
                    <p class="mb-4">
                        A specialized kit designed for forensic serology includes a 5x waveband forensic light source for the detection and examination of body fluids, along with additional accessories.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Included Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Cold White</li>
                        <li>UV 350-380nm</li>
                        <li>Violet 395-425nm</li>
                        <li>Blue 420-470nm</li>
                        <li>Blue/Green 445-510nm</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Simple 3-button interface for easy operation.</li>
                        <li>Integral LCD display for monitoring light source performance.</li>
                        <li>Functions include quick cycling through illumination wavebands, multi-LED combination selection, and adjustable output intensity.</li>
                        <li>Self-test ISO standard performance check and integral dark adaptation checker.</li>
                        <li>Battery status monitoring for runtime and power source.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}