import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Crime-lite_ML2.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® ML2</h2>

                    <p class="mb-4">
                        The Crime-lite® ML2 is a bench-top forensic magnifier designed for laboratory use, featuring multi-wavelength illumination and an infrared module. This system combines high-intensity LED illumination with a wide-area bi-ocular magnifier, making it ideal for examining large pieces of evidence.
                    </p>



                    <h3 class="font-semibold text-lg mb-2">Advanced Forensic Capabilities:</h3>
                    <p class="mb-4">
                        The Crime-lite ML2’s advanced illumination options, including ultraviolet, infrared, and visible light, allow for detecting evidence such as body fluids, bone fragments, fibres, drugs, explosives, accelerants, and chemically treated marks.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Infrared & UV-Vis-IR Functionality:</h3>
                    <p class="mb-4">
                        The optional Infrared LED module and UV-Vis-IR camera enhance the system's capabilities, enabling the detection of hard-to-spot evidence like blood or gunshot residues (GSR) on dark or patterned fabrics. Infrared illumination helps reveal this evidence by extending the examination beyond visible light.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Available Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>White 400-700nm</li>
                        <li>UV 350-380nm</li>
                        <li>Violet 395-425nm</li>
                        <li>Blue 420-470nm</li>
                        <li>Blue/Green 445-510nm</li>
                        <li>Green 480-560nm</li>
                        <li>Orange 570-610nm</li>
                        <li>Red 600-660nm</li>
                        <li>Infrared 800-900nm</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Optional Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Bandpass Viewing Filters</li>
                        <li>Infrared Accessories for Crime-lite ML2</li>
                        <li>Laboratory Cart</li>
                        <li>Optional Vis/IR Camera Module with image capture software</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Optimized for Laboratory Use:</h3>
                    <p class="mb-4">
                        The Crime-lite ML2 is optimized for laboratory work, featuring a heavy-duty bench or wall-mounted arm and adjustable head mount. It is perfect for examining large items of evidence while providing versatility through its multi-wavelength lighting system.
                    </p>



                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>8 x high-efficiency White LEDs for general search</li>
                        <li>Customizable with additional narrowband LED modules (UV, Violet, Blue, Green, Orange, Infrared)</li>
                        <li>3.5 dioptre magnifying lens for detailed examination</li>
                        <li>Optimized for examining large items of evidence in the laboratory</li>
                        <li>Heavy-duty bench or wall-mounted arm</li>
                        <li>Three-axis adjustable head mount</li>
                        <li>Air filter to maintain a clean working environment</li>
                        <li>100-240V AC power input with power-saving mode</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}