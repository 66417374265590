import React from 'react'
import { TabGroup } from '@statikly/funk'

import pdf from "./MOBIfumebrochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    Cyanoacrylate (superglue) fuming is a method for developing fingerprints on non-porous surfaces, such as metal, glass, and plastics. Our MOBIfume series has been developed specifically for those who need all the high quality and reliability of a laboratory fuming chamber in a mobile unit suitable for use right at a crime scene or in a larger fuming space such as a garage or evidence treatment tent.

                    MOBIfume is a new innovative system that lets users fume an entire crime scene at once if needed. Our modular system can be scaled depending on the size and shape of the space you need to fume, whether you need to develop evidence in a small fuming tent or a large airplane hangar. Each MOBIfume unit can run a complete cycle, including a purge cycle to remove leftover cyanoacrylate from the air in your treatment area, using an efficient activated carbon filter.
                    Just like with our laboratory MEGAfume fuming chambers, users have full control over the parameters used during evidence development. Our wireless tablet system lets users control and monitor all fuming units remotely, adjust cycles as needed, monitor filter life with the filter management system, and even form groups of fuming units if they want to develop evidence in multiple different spaces at the same time.
                    Our systems can be set up, taken down, and even dismantled for routine cleaning without any special tools. They also come in sturdy handled tarpaulin bags that are easy to wipe clean and are practical for both storage and transportation.
                    PRODUCT DETAILS
                    Innovative Modular Design
                    MOBIfume standard orders come with a wireless tablet and two complete fuming units. Each fuming unit is a modular all-in-one system that can be connected to the tablet wirelessly and run a full fuming cycle. Settings for the cycle steps, including the humidification, fuming, and purge cycle can all be monitored and adjusted via the tablet and more than one unit can be run at once, even if different cycle settings are needed.
                    Innovative Control System
                    MOBIfume is controlled via a large rechargeable multilingual full color touch screen tablet. Once fuming units are connected to power, the tablet connects to the MOBIfume fuming units wirelessly. Users can control units individually, form groups of them and run them all with the same settings, or run different units with different settings at the same time if needed.
                    Scalable Concept
                    MOBIfume standard orders come with a wireless tablet and two complete fuming units. Each fuming unit is a modular all-in-one system that can be connected to the tablet wirelessly and run a full fuming cycle. A single fuming unit can fume a room 50 – 60  m³. Adding another unit makes it possible to fume 100 – 120  m³ of space. Thanks to our modular and scalable design, users can connect up to 99 fuming units to a single tablet at once, allowing large garages and even many different rooms in a building to be fumed at the same time.
                    Innovative Filter Management
                    Just like our other cyanoacrylate fuming systems, MOBIfume systems utilize a high quality low-dust carbon filter for removing cyanoacrylate from the fuming area after a cycle is completed. This filter is highly effective at purging the chamber air, meaning no addition external ventilation system is required, and the filter can quickly be swapped out without any tools when it’s time for a filter change. Our modular design also lets users swap parts between different fuming units if needed.
                    MOBIfume Black Edition
                    Our standard MOBIfume units have a durable white powder-coating. In addition to the standard white version, we also now offer the MOBIfume Black Edition with a high quality black coating.

                    Users who have worked with cyanoacrylate before know that it doesn’t just stick to evidence, it sticks to equipment too. If this isn’t removed, it can contaminate other evidence it is used to help treat, and it can cause equipment to wear down quickly and malfunction over time. We know this is important, so in addition to making sure all our MOBIfume units can be easily dismantled and cleaned without tools, this special black coating on MOBIfume Black is resistant to cyanoacrylate adhering to it.
                    Specialized Evidence Fuming Tent
                    While MOBIfume can be used right at a crime scene, sometimes it’s necessary or useful to be able to create your own fuming room on the go. This gives you the option to fume many pieces of evidence in one space, or build a fuming room around a large piece of evidence, like a car, that might be difficult to transport.

                    For this, we offer a specialized fuming tent for cyanoacrylate fuming. Our fuming tents have been specially designed to be used with and produce high quality results with MOBIfume. Tents can be set up quickly and easily without tools, and fold down into compact storage bags.

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        Forensic – Fingerprint
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        The fully functional basic kit features:
                        <br /> 1x MOBIfume unit with humidifier, cyanoacrylate fuming plate and air filter system
                        <br /> Set of connection cables for each MOBIfume unit
                        <br /> Control Tablet with touch screen operation and wireless link into the fuming room
                        <br /> Transport bags made from sturdy and easy to clean tarpaulin
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download> download </a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}