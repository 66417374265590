import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/EverASM Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Everspry Automated Shoeprint Matcher</h2>

                    <p class="mb-4">
                        Our EverASM software uses advanced AI technology to quickly identify and match against tens of thousands of shoeprints in the footwear impression database, effectively increasing the value of shoeprint evidence in solving criminal cases.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">An AI Algorithm that Delivers Precise Shoeprint Matching:</h3>

                    <p class="mb-4">
                        EverASM uses an AI algorithm that can deliver precise shoeprint matching by effectively extracting high-quality pattern features. This algorithm is designed to identify and analyze different patterns present on the shoeprint, enabling it to provide accurate results. The algorithm uses machine learning techniques to learn from the data it analyzes, continually improving its performance over time. This feature allows forensic specialists to quickly identify potential leads and build strong cases based on available evidence.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">High-Speed Shoeprint Search Engine:</h3>

                    <p class="mb-4">
                        EverASM offers a high-speed shoeprint search engine that facilitates swift retrieval of shoe models from an extensive database. This feature significantly streamlines the shoeprint analysis process and enables forensic specialists to quickly identify matching shoe models based on crime scene shoeprints. The search engine is designed to be highly efficient, enabling users to search through large amounts of data in a short amount of time.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Customizable Footwear Database Solution:</h3>

                    <p class="mb-4">
                        EverASM also offers a customizable footwear impression database solution that provides tailored data fields for more precise and efficient analysis. This feature allows users to create a customized database according to their unique requirements, which significantly enhances the software's overall efficiency. Forensic specialists can use this feature to add relevant information to the database, such as shoe manufacturer, brand, and model, which can be used to quickly identify matching shoe models.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Fast Search Function:</h3>

                    <p class="mb-4">
                        The fast search function is a key feature of EverASM that allows users to quickly and accurately identify matching shoe models from our extensive database based on crime scene shoeprints. This feature significantly reduces the time required for analysis, enabling forensic specialists to quickly identify potential leads and build strong cases based on available evidence. The speedy search function is designed to be highly efficient and accurate, ensuring that the results provided are reliable and useful.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Seamless Integration with EverOS:</h3>

                    <p class="mb-4">
                        EverASM seamlessly integrates with EverOS, which is an advanced operating system designed for forensic investigations. This integration enables the automatic creation of an image database of acquired outsole patterns via the Everspry Outsole Scanner. The integration provides a secure and efficient way to manage the data collected during forensic investigations, enhancing the overall efficiency and accuracy of the investigation.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Case-Linking to Enhance Case-Solving:</h3>

                    <p class="mb-4">
                        EverASM also includes a case-linking feature that enhances case-solving efficiency by linking shoeprints with similar characteristics and analyzing related cases. This feature allows forensic specialists to quickly identify potential leads and build strong cases based on available evidence. The case-linking feature is designed to be highly efficient and accurate, ensuring that the results provided are reliable and useful.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Two Deployment Options:</h3>

                    <p class="mb-4">
                        EverASM offers two deployment options, allowing users to choose between a standalone or network version based on their unique requirements. The standalone version is ideal for users who require a high degree of security and isolation. The network version is ideal for users who need to access the software from multiple locations or share data with other users. These two deployment options ensure that users can maximize resources and customize their deployment based on their specific needs. Additionally, the software can work completely offline, ensuring full isolation and police data security, which is essential for maintaining the integrity of the evidence.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}