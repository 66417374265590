import React from "react";
import { Link } from "react-router-dom";

import img from "./PAGScan 3000/Images/645babae225b5e5b7cc3ac81_P0334 RESIZED-2.webp"
import img1 from "./Regula 70X4M/Images/70X4M_2.webp"
import img2 from "./Regula 70X7/Images/70X7_1.webp"
import img3 from "./Regula 70X8M/Images/70X8M_2.webp"
import img4 from "./Regula 70X9/Images/70X9_1.webp"
import img5 from "./Regula 72X3/Images/72Х3_2.webp"
import img6 from "./Regula 7308/Images/7308_3.webp"
import img7 from "./Regula 7310/Images/7310_1.webp"
import img8 from "./Regula 83X3M/Images/83X3M_1.webp"


export default function Pageroutpass() {
    return (
        <div className="w-full xl:pt-28">
            <div className="w-full items-content justify-center flex bg-[#7a1548] py-7">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 flex">
                    <div className="w-1/2">
                        <span className="text-white text-xl">Machine Ssisted Document Verification</span>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <div className="text-white text-xs">
                            <a href="/">Home</a><span> > </span><span>Document Examination Verification Systems > Machine Ssisted Document Verification </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full items-content justify-center flex mb-5">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 py-12 items-content justify-center">
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img} alt="img" className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">PAGScan 3000</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/PAGScan3000" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img1} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 70X4M</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula70X4M" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img2}  className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 70X7</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula70X7" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img3} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 70X8M</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula70X8M" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img4} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 70X9</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula70X9" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img5} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 72X3</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula72X3" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img6} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 83X3M</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula83X3M1" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img7} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 7308</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula730811" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                       
                    
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img8} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Regula 7310</span><br /><br />
                                <a href="/document-examination-verification-systems/machine-assisted-document-verification/Regula7310" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        
                       
                    
                    </div>
                   
                    
                </div>
            </div>
        </div>
    )
}