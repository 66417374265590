import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Crime-lite_2.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® 2</h2>

                    <p class="mb-4">A range of lightweight LED forensic light sources effective at short range providing intense, even and shadow-free illumination for locating and examining evidence.</p>

                    <p class="mb-4">
                        The range includes a white light model for general search and eight narrow band light sources with wavelengths in UV, violet, blue, blue-green, green, orange, and red for detecting fluorescent stains.
                    </p>

                    <p class="mb-4">
                        With thousands of units in active use by crime scene and forensic investigators worldwide, the Crime-lite® 2 has proven effectiveness and reliability. Crime-lite® 2 light sources are available individually or in sets with viewing goggles or spectacles.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Features and Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>1 x high-efficiency LED:</strong> Provides powerful illumination.</li>
                        <li><strong>Up to 1.2W LED output power</strong></li>
                        <li><strong>Uniform shadow-free beam:</strong> Ensures clarity in illumination.</li>
                        <li><strong>Runs on AC mains or rechargeable battery:</strong> Offers flexibility in power options.</li>
                        <li><strong>Battery runtime up to 240 minutes:</strong> Long-lasting battery for extended use.</li>
                        <li><strong>Constant light output:</strong> Maintains intensity throughout the battery charge.</li>
                        <li><strong>Low battery indicator:</strong> Alerts when the battery is low.</li>
                        <li><strong>Robust and splashproof:</strong> Built for durability in challenging environments.</li>
                        <li><strong>Lightweight, 460g:</strong> Easy to handle and transport.</li>
                    </ul>



                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Features and Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>1 x high-efficiency LED:</strong> Provides powerful illumination.</li>
                        <li><strong>Up to 1.2W LED output power</strong></li>
                        <li><strong>Uniform shadow-free beam:</strong> Ensures clarity in illumination.</li>
                        <li><strong>Runs on AC mains or rechargeable battery:</strong> Offers flexibility in power options.</li>
                        <li><strong>Battery runtime up to 240 minutes:</strong> Long-lasting battery for extended use.</li>
                        <li><strong>Constant light output:</strong> Maintains intensity throughout the battery charge.</li>
                        <li><strong>Low battery indicator:</strong> Alerts when the battery is low.</li>
                        <li><strong>Robust and splashproof:</strong> Built for durability in challenging environments.</li>
                        <li><strong>Lightweight, 460g:</strong> Easy to handle and transport.</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Anti-glare Viewing Goggles and Camera Filters</li>
                        <li>Crime-lite 2 Infrared Accessories</li>
                        <li>Magnifier Attachment</li>
                        <li>Laboratory Arm</li>
                        <li>Laboratory Cart</li>
                        <li>Crime Scene Accessories</li>
                        <li>Power Supply Components</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Available Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>White: 400-700nm</li>
                        <li>UV: 350-380nm</li>
                        <li>Violet: 395-425nm</li>
                        <li>Blue: 420nm-470nm</li>
                        <li>Blue/Green: 445-510nm</li>
                        <li>Green: 480-560nm</li>
                        <li>Orange: 570-610nm</li>
                        <li>Red: 600-660nm</li>
                        <li>Infrared: 800-900nm</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Anti-glare Viewing Goggles and Camera Filters</li>
                        <li>Crime-lite 2 Infrared Accessories</li>
                        <li>Magnifier Attachment</li>
                        <li>Laboratory Arm</li>
                        <li>Laboratory Cart</li>
                        <li>Crime Scene Accessories</li>
                        <li>Power Supply Components</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Available Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>White: 400-700nm</li>
                        <li>UV: 350-380nm</li>
                        <li>Violet: 395-425nm</li>
                        <li>Blue: 420nm-470nm</li>
                        <li>Blue/Green: 445-510nm</li>
                        <li>Green: 480-560nm</li>
                        <li>Orange: 570-610nm</li>
                        <li>Red: 600-660nm</li>
                        <li>Infrared: 800-900nm</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}