import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/EverOS V2.0 Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Everspry Outsole Scanner</h2>

                    <p class="mb-4">
                        EverOS is an industry-leading digital acquisition equipment designed specifically for acquiring low-cost and clean outsole shoe patterns. With its advanced technology and powerful features, EverOS offers high-resolution image acquisition, automatic saving of images, and low-cost data storage, making it a cost-effective solution for law enforcement agencies. EverOS boasts excellent load-bearing capacity and a large outsole acquisition area, which ensures its versatility and durability, making it ideal for use in police stations and forensics labs. Whether it's capturing shoeprints for investigations or identifying suspects, EverOS is the perfect tool for police officers looking for a reliable and efficient solution for outsole pattern acquisition.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Rapid Outsole Pattern Acquisition:</h3>

                    <p class="mb-4">
                        EverOS is designed to capture outsole patterns of shoes quickly and efficiently, with the capability of capturing the pattern accurately in under 3 seconds. This speed saves valuable time and reduces the workload for police officers, allowing them to process suspects quickly and efficiently. The rapid acquisition also ensures that the suspects can be moved through the booking process quickly, minimizing the time they spend in holding.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">High-Resolution Shoeprint Image Acquisition:</h3>

                    <p class="mb-4">
                        EverOS offers 300 DPI high-resolution image acquisition capabilities, which ensures that detailed shoeprint images are captured accurately with less than 0.2% distortion. High-resolution images are crucial for investigations, as they allow police officers to obtain clear and precise images that can be used as evidence in court. With this feature, police officers can easily compare shoeprints found at crime scenes with those of suspects, increasing the chances of identifying and prosecuting the perpetrators.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Low-Cost Data Storage:</h3>

                    <p class="mb-4">
                        EverOS offers a significant cost reduction of 98% compared to traditional shoeprint lifting methods. This low-cost data storage feature makes it affordable for police departments of all sizes, allowing them to invest in modern technology that improves their efficiency. The device saves captured images locally on the computer, eliminating the need for expensive cloud-based storage. This feature ensures that the data is secure and readily accessible whenever needed, making the data management process more efficient.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Automatic Saving of Images:</h3>

                    <p class="mb-4">
                        EverOS automatically saves the captured images or uploads them into the EverASM database, eliminating the possibility of losing crucial evidence due to human error. This feature ensures that all images captured by the device are securely stored and readily available for future reference, ensuring that valuable data is not lost. The automatic saving of images makes it easier for police officers to manage and organize the data, making their jobs more efficient.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Excellent Load-Bearing Capacity:</h3>

                    <p class="mb-4">
                        EverOS is designed to handle heavy loads with a maximum weight capacity of 200 kg for a single person. This excellent load-bearing capacity feature makes it suitable for use in high-traffic areas such as busy police stations, where many people need to be processed. This feature ensures that the device is durable and can withstand the demands of a busy police station, improving its reliability and longevity.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Large Outsole Acquisition Area:</h3>

                    <p class="mb-4">
                        EverOS features a large outsole acquisition area of 380 mm in length and 160 mm in width, ensuring that it can capture images of a variety of shoe sizes. This large outsole acquisition area feature ensures that police officers can acquire outsole patterns from any shoe size, making the device versatile and convenient to use. With this feature, police officers can capture outsole patterns of all shoe sizes, making it easier to identify suspects and provide accurate evidence.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                                        <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}