import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochure/CrimeEvent FDC018_Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">CrimeEvent Fingerprint Development Chamber</h2>


                    <p class="mb-4">
                        The Weiss Technik CrimeEvent Fingerprint Development Chamber rapidly develops and visualise fingerprints on a variety of porous surfaces using 1,8-Diazafluoren-9-one (DFO) and ninhydrin processes. The environmental chamber produces controlled temperature conditions in combination with high humidity to accelerate the ninhydrin process, thus producing excellently clear finger traces in just 3 minutes. This means that important information for combating crime can be provided almost immediately.
                        The fingerprint development chamber is a bench top, 185L capacity chamber that has been designed to enable batch development of fingerprints in conditions with elevated temperatures and with or without controlled relative humidity.
                        The DFO process is enhanced by subjecting the samples to elevated temperatures with low humidity, whereas the Ninhydrin proof is enhanced by subjecting samples to elevated temperatures and high humidity (usually 80°C and 62% RH).

                    </p>

                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Fully Integrated Imaging Workstation:</strong> AARI is designed to meet the needs of high-demand fingerprint laboratories, providing a complete solution for illuminating and photographing high-resolution fingermark images.</li>
                        <li><strong>Easy-to-Use Interface:</strong> From placing a document on the examination bed to exporting a full report, AARI streamlines the entire fingerprint processing workflow.</li>
                        <li><strong>Pre-set and Manual Operation:</strong> Use pre-configured examination settings or operate manually to reveal and capture fingermarks treated with amino acid reagents such as:</li>
                        <ul class="list-disc ml-10">
                            <li>Ninhydrin</li>
                            <li>1,2-Indandione</li>
                            <li>DFO</li>
                            <li>Cyanoacrylate</li>
                            <li>Physical Developer</li>
                            <li>Vaporized Metal Deposition (VMD)</li>
                            <li>Silver Nitrate</li>
                            <li>Iodine</li>
                            <li>Oil Red O</li>
                        </ul>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Fast – Efficient – Flexible:</h3>


                    <ul class="list-disc ml-5 mb-4">
                        <li>	Results in less than 3 minutes</li>
                        <li>	Large numbers of samples can be processed</li>
                        <li>	Higher throughput in less time</li>
                        <li>	Controlled conditions ensure optimum clarity</li>
                        <li>	Simultaneous processing of different-sized objects and multiple samples</li>
                    </ul>




                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                  
                    <ul class="list-disc ml-5 mb-4">
                        <li>•	Flexible shelving system</li>
                        <li>•	Adjustable timer and alarm</li>
                        <li>•	Independent excess temperature thermostat</li>
                        <li>•	Combined temperature and humidity sensor</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a her={pdf} download />
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}