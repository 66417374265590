import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/Brochures.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">BWT-250 Bullet Recovery Tank</h2>

                    <p class="mb-4">
                        Our bullet recovery tank provides an effective and high-performance solution to acquire comparison bullets from different types of firearms, including rifles and pistols.
                    </p>

                    <p class="mb-4">
                        BWT-250 Bullet Recovery Tank is the fastest system compared to conventional bullet recovery systems, such as shooting into cotton tubes and fleece layers. The system offers fast, safe, durable, and easy-to-use features for ballistic experts, along with accurate results.
                    </p>


                    <h3 class="font-semibold text-lg mb-2">Supported Ammunition:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li>9 mm CZ-75 B Pistol - 9x19mm</li>
                        <li>9 mm MP-5 SMG - 9x19mm</li>
                        <li>7.62 mm AK-47 Assault Rifle - 7.62x39mm</li>
                        <li>7.62 mm G-3 Assault Rifle - 7.62x51mm</li>
                        <li>7.62 mm Dragunov Sniper Rifle - 7.62x54mm</li>
                        <li>12.7 x 0.99 mm - .50 BMG</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Single Solid Body:</strong> The BWT-250 is designed in a single body form, enabling shooting with high-caliber firearms such as the 12.7mm (.50 BMG). The system is durable under high-pressure shooting conditions and equipped with shock absorbers beneath the tank, making it capable of handling multiple shooting tasks.</li>

                        <li><strong>Smart Pressure Evacuation:</strong> While shooting into bullet recovery water tanks, firearms produce extremely high levels of pressure. Our Smart Pressure Evacuation System is designed to safely manage this pressure, allowing operators to shoot continuously without interruption.</li>

                        <li><strong>Suction Wand:</strong> For bullet recovery, a suction wand is utilized. Due to the high-pressure tolerance requirement, the BWT-250 is designed as a single body. By activating the suction wand, examiners can collect fired bullets and fragments in just minutes.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}