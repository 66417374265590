import React from 'react'
import { TabGroup } from '@statikly/funk'

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    Manta Ray™ is the answer to the growing problem of unauthorized, illegal, unsecured & contraband cell phone use in correctional facilities, government buildings, corporate environments, financial institutions, universities & law enforcement agencies.
                    <br /><br />>>Detects all cell phones even when switched OFF and battery removed
                    <br />>>Minimize false triggers from common items like keys, belt buckles, coins, watches, etc.
                    <br />>>Cost effective & simple operation for anyone
                    <br />>>Runs on standard, removable 9V battery for up to 3 hours
                    <br /><br />Manta Ray™ Cell Phone Detector is the answer to the growing problem of unauthorized, illegal, unsecure & contraband cell phone use in correctional facilities, government buildings, corporate environments, financial institutions, universities & law enforcement agencies.
                    <br /><br />Berkeley’s unique cell phone detection device is a close range TSCM security scanner for concealed mobile phones (ON or OFF and even with battery removed). Manta Ray™ differs from traditional metal detectors in that it scans for specific components common to all cell phones and not just metal. This reduces false triggers* such as watches, keys, coins, belt buckles and other common metal items that may trigger a standard metal detector. Manta Ray™ is the perfect tool for any security detail tasked with rapid scans of many targets including people, packages, bags, briefcases, purses & more. Manta Ray™ supports both HANDHELD and all new STATIONARY mode for ultra-sensitive package inspections.
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">
                        >>Minimize false triggers from common items like keys, belt buckles, coins, watches, etc.
                        <br /><br />>>Cost effective & simple operation for anyone
                        <br /><br />>>Supports handheld & NEW stationary mode
                        <br /><br />>>Runs on standard, removable 9V battery for up to 3 hours
                        <br /><br />>>Quick scan anyone for unauthorized cell phones – pockets, backpacks, purses & briefcases
                        <br /><br />>>Detect phones hidden under mattresses, behind walls & even inside packages
                        <br /><br />>>Detects cell phones even when switched OFF and battery removed
                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}