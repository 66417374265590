import React from 'react'
import { TabGroup } from '@statikly/funk'

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p>The XCAT handheld detection system features one-step detection of narcotics, explosives, and gunshot residue.  The XCAT uses dedicated sampling cards to presumptively detect either individual analytes or groups of related analytes. The XCAT detection cards utilize specially formulated optical detection inks and software to identify characteristics unique to each individual or group of chemically related analytes and displays either a red or green light signal to the user to indicate “detection” or “no detection”.</p>
                    <p>Satisfied clients include Law Enforcement and Criminal Investigation Agencies, Oil Refineries, Museums, IT Companies, Arenas, Universities, Correctional Facilities, Banks, Airports, Military Facilities, and Coroners.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        <p>>>Forensic-Chemistry</p>
                        <p>>>Forensic-Firearms</p>
                        <p>>>Law Enforcement</p>
                        <p>>>Border Security</p>
                        <p>>>Military</p>
                        <br />
                        <p>Uses:</p>
                        <p>>>Sample trace or bulk narcotics.</p>
                        <p>>>Test a suspect’s hands for the presence of gunshot residue – immediate results guide the investigation and force confessions.</p>
                        <p>>>Clear unattended bags for explosives.</p>
                        <p>>>Test mail or packages at incoming inspection.</p>
                        <p>>>Vehicle inspection at entry points.</p>
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        <p>>>Narcotics, Explosives and GSR detection in a single platform.</p>
                        <br />
                        <p>>>Rapid red light/green light response.</p>
                        <br />
                        <p>>>No glass ampoules, no color charts, no spraying, no chemical exposure</p>
                        <br />
                        <p>>>Trace detection.</p>
                        <br />
                        <p>>>No calibration</p>
                        <br />
                        <p>>>Less than 1 pound</p>
                        <br />
                        <p>>>Rechargeable Li-ion battery with 7 hour battery life.</p>
                        <br />
                        <p>>>Verification standards provided.</p>
                        <br />
                        <p>>>Field upgrade-able for new threats.</p>
                        <br />
                        <p>>>Affordable for every security agency.</p>
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}