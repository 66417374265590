import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/EverSole Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Everspry Shoe Model Database</h2>

                    <p class="mb-4">
                        The shoe model database is a comprehensive collection of footwear models that is regularly updated and meticulously maintained. It includes a wide variety of shoe types, sizes, and designs, making it an invaluable resource for forensic experts in analyzing and identifying shoeprints.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Comprehensive Shoe Model Data:</h3>

                    <p class="mb-4">
                        EverSole provides comprehensive shoe model data from over thousands of global shoe brands, totaling over 100,000 shoe models as of 2023. The database is carefully curated and includes a wide range of shoe models, providing valuable insights to forensic investigators. This feature allows users to quickly and accurately identify different shoe models, enabling them to build strong cases based on available evidence.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Regular Annual Updates:</h3>

                    <p class="mb-4">
                        EverSole undergoes regular annual updates, with a team of experts processing and updating over 700 large global shoe brands and types each year. These updates ensure that the database remains up-to-date and accurate, providing valuable insights to users. This feature enables users to access the latest information on global shoe brands and types, enhancing the overall efficiency and accuracy of forensic investigations.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">High-Quality Shoe Models:</h3>

                    <p class="mb-4">
                        EverSole offers high-quality shoe models with comprehensive textual information, including shoe brand, color, price, style, and multiple images of the shoe's upper. This feature enables forensic investigators to quickly and accurately identify different shoe models, providing valuable insights into the potential suspect. Additionally, the high-quality shoe models provided by EverSole ensure that the results provided are reliable and useful, enhancing the overall efficiency and accuracy of forensic investigations.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                                     <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}