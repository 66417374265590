import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/EverFACT Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Everspry Footwear Analysis and Comparison Tool</h2>

                    <p class="mb-4">
                        This cutting-edge tool is the world's first of its kind, providing forensic experts with an expert-level analysis and comparison system for shoeprints. Designed to identify and compare unique characteristics of shoeprints found at crime scenes, this advanced tool utilizes the latest technology to increase the accuracy and reliability of forensic analysis.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Wide Range of Feature Marking Tools:</h3>

                    <p class="mb-4">
                        EverFACT provides a wide range of feature marking tools, including line, curve, circle, rectangle, polyline, and more. These tools enable experts to mark and highlight different features on the shoeprint for detailed analysis. By using these tools, experts can identify key patterns and features, such as wear patterns or unique design elements, which can help to identify the potential suspect.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Precise Shoeprint Comparison:</h3>

                    <p class="mb-4">
                        EverFACT uses overlapping comparison at the same scale to provide precise shoeprint comparison results. This feature ensures that experts can accurately compare different shoeprints and identify similarities or differences. Additionally, EverFACT allows experts to export the comparison results, enabling them to share their findings with others and build strong cases based on available evidence.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Pattern Feature Management:</h3>

                    <p class="mb-4">
                        EverFACT manages pattern features through categorical classification, enabling effective organization and management of the different patterns present in the shoeprint. This feature helps experts to efficiently analyze the shoeprint and identify potential leads based on the unique patterns and features.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Project-Based Shoeprint Image Management:</h3>

                    <p class="mb-4">
                        EverFACT provides project-based shoeprint image management, enabling users to save and compare multiple shoeprints in engineering mode for efficient operation and management. This feature allows experts to quickly and accurately compare different shoeprints, enabling them to build strong cases based on available evidence. The project-based shoeprint image management feature ensures that experts can efficiently manage and analyze the data, enhancing the overall efficiency and accuracy of the forensic investigation.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                                       <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}