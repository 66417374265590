import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/VisionX Brochure 2024 EN for web.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">VisionX Motorized Object Holders</h2>

                    <p class="mb-4">
                        With years of superior optical engineering poured into the design, the VisionX system provides unprecedented comfort and clarity – a difference you can feel as well as see.
                    </p>



                    <h3 class="font-semibold text-lg mb-2">Superior Optics:</h3>
                    <p class="mb-4">
                        Apochromatically corrected with adjustable apertures, each objective provides three fixed and precisely matched magnification levels for optimum reproducibility at the touch of a button.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Ergonomic Comfort:</h3>
                    <p class="mb-4">
                        Innovative binocular design with motorized height adjustment and a constant 30-degree viewing angle for optimum comfort for examiners of any height.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Intuitive Control:</h3>
                    <p class="mb-4">
                        Real-time, intuitive controls allow for synchronized or separate movements; motorized objective and magnification changer are completely synchronized and precisely matched with each other.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Optimal Lighting:</h3>
                    <p class="mb-4">
                        A flexible, state-of-the-art illumination system that eliminates unwanted reflections and supports consistent lighting of objects, regardless of shape, size, surface, and structure.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Technical Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Comparison bridge with a distance of optical axes of 450 mm.</li>
                        <li>Objective 0.6x for magnification 6x, 11x, 20x; working distance: 116 mm.</li>
                        <li>Objective 3.2x for magnification 32x, 58x, 104x; working distance: 120 mm.</li>
                        <li>Intermediate 3 steps magnification changer.</li>
                        <li>Ergo tube with motorized user setup and height adjustment; eyepieces 10x/22.</li>
                        <li>Basic stand with motorized X and Y movement; travel range 50 mm, motorized Z-axis column with 146 mm movement.</li>
                        <li>System control unit with joystick and wheel operation.</li>
                        <li>Holder for spotlight and ring light; LED ring light, LED high power spotlight with intensity control.</li>
                        <li>Tool station included.</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Optional Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Objective module 0.13 for magnifications 1.3x, 2.3x, and 4.2x; working distance 233 mm.</li>
                        <li>Objective module 7.5x for magnification 75x, 135x, and 243x; working distance 95 mm.</li>
                        <li>A wide range of innovative accessories, lighting options, stages, object holders, etc., is available.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Clear, simultaneous viewing through the binoculars or on-screen with the live camera.</li>
                        <li>Synchronized, three-step magnification changer for precise adjustments.</li>
                        <li>Parafocal optical system that maintains focus without refocusing.</li>
                        <li>Centralized control unit for ease of operation.</li>
                        <li>Various LED lighting systems with flexible arrangement to enhance visibility.</li>
                        <li>Motorized X/Y/Z movement with the possibility of synchronizing the left and right object stages.</li>
                        <li>Easy exchange of different object holders for versatility in examinations.</li>
                        <li>Unmatched ergonomics with an adjustable binocular tube and a constant 30-degree viewing angle.</li>
                        <li>Superior stability, delivering a clear, uncluttered work area.</li>
                        <li>Unprecedented working distance of 95-233 mm, making it easier to position objects and handle larger evidence.</li>
                        <li>Razor-sharp images at 1.3x to 243x magnification for unparalleled documentation.</li>
                        <li>Autofocus function ensuring unsurpassed image quality.</li>
                        <li>VisionX’s unique design offers both stability and a user-friendly working environment.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}