import React from 'react'
import { TabGroup } from '@statikly/funk'
import NINchabrochure from "./NINchabrochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    NINcha is a climate chamber specially designed for developing fingerprints and similar marks on porous surfaces. There are three sizes of chamber in this series, the benchtop model NINcha S31, the mid-size standing model, NINcha M31 and the larger standing model, NINcha L31.
                    Evidence with fingerprints is treated in the usual way by dipping or spray with developer like Ninhydrin, DFO or Indandione, and after an evaporation step, is developed in a climate chamber. This allows the evidence to be developed under the ideal temperature and humidity conditions for the best results.
                    Many laboratories attempt to do the development step in a cabinet or chamber designed for other uses. These, unfortunately, are not ideal for evidence development. Not only can they produce inferior results, less standardized methods threaten laboratory credibility, and use of improper equipment can contaminate evidence and even cause it to develop mold.
                    NINcha was specially developed for forensic laboratory use, using the feedback of professionals working in the field. With over 500 systems installed worldwide, NINcha is a hallmark of modern day forensic equipment standards for fingerprint development on porous surfaces.
                    All NINcha series models are constructed with the same innovative details in mind, using future-proofed state-of-the-art production techniques.

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        Forensic – Fingerprint
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        <p className='text-2xl'>Key advances of the NINCha include:</p>

                        <p className='font-bold'>Interior Concept and Ventilation</p>
                        <p>
                            The inner climate chamber of every NINcha is made from high quality smooth stainless steel, making it easy to clean and resistant to corrosive material. This makes removing any DNA contamination simple and allows peroxide-based cleaners to be used, as these cleaners would damage and be less effective on surfaces on painted and plastic surfaces. All evidence shelving, rods, and hooks are also made from stainless steel, making them rust resistant. Their size also makes them easy to clean in a laboratory dishwasher.
                            NINcha does not have any hidden air channels to help circulate air in the chamber. This reduces contamination buildup and makes it easier to clean the chamber. All interior chamber accessories can be removed without tools for even easier access, making cleaning simpler and safer.
                        </p>
                        <p className='font-bold'>

                            Innovative Operation
                        </p><p>

                            Users can control NINcha via a simple user-friendly touch-panel display. The system is highly customizable, allowing users to control and monitor all their development parameters. Not only does the system have pre-programmed cycles for easy one-button development using common developers on the market (such as DFO), users can adjust these pre-programmed cycles or even create their own custom cycles for completely new developers that enter the market in the future.
                        </p>

                        <p className='font-bold'>

                            USB Process Datalogger
                        </p><p>
                            Reliable and reproducible results are a must in forensics, especially for ISO 17025 accredited laboratories. Whether the pre-programmed or the custom manual development settings are used, all process data is stored by the system datalogger and can be easily downloaded with a USB. Parameter data for the entire process can be viewed and saved for documentation and quality assurance.
                        </p>
                        <p className='font-bold'>

                            Integrated Air Circulation System
                        </p><p>
                            Dust can often be generated when developing porous evidence like paper or cardboard. This dust can contain material like DNA that may contaminate other evidence if left in the chamber. Some other development chambers have air circulation that depends on moving air through channels that are difficult to clean and access.

                            Dust and other contaminants can build up in these channels and later be expelled back into the chamber where they may damage or contaminate other evidence. Our open chamber circulation and filter system remove dust from the chamber airstream, reducing this risk and making decontamination easier.

                        </p>
                        <p className='font-bold'>

                            NINcha P31 – the Portable Version
                        </p><p>
                            Dust can often be generated when developing porous evidence like paper or cardboard. This dust can contain material like DNA that may contaminate other evidence if left in the chamber. Some other development chambers have air circulation that depends on moving air through channels that are difficult to clean and access.
                            NINcha P31 is a practical solution for those who need all to develop porous evidence outside a laboratory. This portable version has and the useful control features as the larger NINcha models, and is controlled via a similar touch-panel display, but is built into a compact rolling case with an extendable handle. It can easily be transported to develop evidence right at a scene and still maintain the high standards of the laboratory.

                            This suitcase model can be used with all the same developers as the larger standing models. The interior chamber walls are easy to clean stainless steel, as are the evidence racks. The removable chamber evidence racks can hold evidence as large as two sheets of size DIN-A4 paper.

                        </p>



                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a  href={NINchabrochure} download> download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}