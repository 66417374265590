import React from "react";
import { Link } from "react-router-dom";
import Rightbar from "./right";

export default function AjmanReadmore() {
    const imgs = ["./img/landing/readmore/ajman/1.jpg",
        "./img/landing/readmore/ajman/2.jpg",
        "./img/landing/readmore/ajman/3.jpg",
        "./img/landing/readmore/ajman/4.jpg"
    ];
    return (
        <div className="w-full xl:pt-28">
            <div className="w-full items-content justify-center flex bg-[#7a1548] py-7">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 flex">
                    <div className="w-1/2">
                        <span className="text-white text-xl">AJMAN POLICE EXHIBITION 2023</span>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <div className="text-white text-xs">
                            <a href="/">Home</a><span> > </span><a href="/">News & Events</a><span> > </span><span className="text-white text-xs">Ajman Police Exhibition 2023</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full items-content justify-center flex text-gray-500 mb-5">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 items-content justify-center lg:flex md:flex mr-16">
                    <div className="lg:w-4/5 w-full mx-5">
                        <div className="w-full px-3">
                            <div className="w-full mb-16">
                                <img src="./img/news2.jpg" className="w-full mb-5" />
                                <p className="text-2xl text-gray-700 font-bold">AJMAN POLICE EXHIBITION 2023</p>
                                <p className="text-lg">We are thrilled to share that on Monday March 13th, we had the privilege of participating at an event organized by the Innovative Centre of the Ajman Police. The event featured some of the latest advancements in police technology and equipment, including drones, artificial intelligence, and virtual reality simulations. It was truly inspiring to see how the Ajman Police is leading the way in innovation and embracing the latest technologies to better serve our community.</p>
                                <br />
                                <p className="text-lg">We would like to extend our congratulations to the Ajman Police for organizing such a successful event, and to the officers working in the Innovation Centre for their hard work and dedication. It was truly an honor to take part at this event. We look forward to seeing more innovative solutions from the Ajman Police in the future .</p>
                            </div>
                            <div className="w-full items-content justify-center flex my-5">
                                <div className="w-full">
                                    <ul className="p-4 grid grid-cols-4 gap-4 text-sm leading-6">
                                        {
                                            imgs.map(img =>
                                                <li>
                                                    <div className="w-full lg:pb-10 sm:pb-0 lg:h-48 bg-white px-3">
                                                        <img src={img} className="w-full h-full border-2 hover:cursor-pointer transition duration-500" />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Rightbar />
                </div>
            </div>
        </div>
    )
}