import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='font-semibold'>


                        Complete Collection of Documents and Banknotes at Your Fingertips

                    </p>
                    <p>
                        Information Reference System (IRS) is a database containing
                        images and detailed description of documents, banknotes and
                        their security features at several protection levels. It enables experts
                        and border control officers to compare questioned documents
                        and banknotes against a comprehensive global reference,
                        overcoming the limitations of individual knowledge and access to original specimens.
                    </p>
                    <p>
                        More than 337,000 high-quality images of 12,000+ documents, banknotes and coins from around the world:
                    </p>
                    <ul>
                        <li>162,000+ passport images</li>
                        <li>156,700+ currency images</li>
                        <li>3,700+ visa images</li>
                        <li>14,500+ auto document images</li>
                    </ul>


                    <p className='font-semibold text-gray-500 text-lg'>

                        What makes the Regula IRS Different
                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            High-resolution images
                        </p>
                        Images are captured in 2400 DPI optical resolution to highlight even the slightest nuances of document security features. This makes the Regula IRS the only solution that enables you to determine the printing technique using a reference image.

                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Detailed descriptions
                        </p>

                        Each document comes with a detailed description, including the number of pages, release date, material for each page, and other characteristics, as well as a detailed description of up to 80 security elements: type, location, and printing technique

                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Unique luminescence and magnetic visualization
                        </p>


                        The Regula IRS features infrared (IR) luminescence, anti-Stokes, and ultraviolet (UV) luminescence for all pages, including UV 365, UV 313, and UV 254, along with magnetic protection visualization for applicable pages.

                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Exclusive hologram representation
                        </p>


                        For holograms and other optically variable features, the Regula IRS contains animated GIFs, created with the unique Regula 2303 device, to show how they change visually.
                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Advanced search
                        </p>
                        Users can search by document type, country, ICAO code, light source, security element, printing technique, and other criteria for quick and efficient access to the needed information.

                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Daily updates
                        </p>
                        Regula IRS is being constantly updated. Usually, it takes around two weeks to examine, capture, and describe a new document before adding it to the collection.
                    </p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Improved Verification Across a Wide Rage of Light Sources
                        </p>
                        Document pages and banknote images are captured in different light sources: white (incident and transmitted), ultraviolet (365, 313, 254 nm), infrared (870 nm), along with IR and anti-Stokes luminescence so that an expert can thoroughly check a document under various lights</p>
                    <p>
                        <p className='font-semibold text-gray-500 text-lg'>
                            Comprehensive Ecosystem for Document Verification
                        </p>
                        The IRS can be integrated into other Regula solutions, such as Regula Forensic Studio software (which supports all Regula forensic devices), document readers, or the advanced border control device Regula 4205D
                        . Users can access the IRS directly from the device management
                        interface and operate in comparison mode. Additionally, users can
                        extend the IRS’s functionality by using Regula Document Builder
                        software to add their own samples, for example by providing information on common regional forgeries.  </p>
                    <h2 className='font-semibold text-gray-500 text-lg'>IRS Versions</h2>
                    <ul>
                        <li><strong>Brief (73,200+ images)</strong> — Includes only document and banknote images in different spectral ranges for basic control</li>
                        <li><strong>Express (212,800+ images)</strong> — Includes some basic security features, document, and banknote images for express control</li>
                        <li><strong>Forensic (322,000+ images)</strong> — Includes security features and images for advanced document and banknote examination</li>
                        <li><strong>Ultra (337,600+ images)</strong> — Professional version for expert examination of documents and banknotes, which includes additional images of documents (anti-Stokes luminescence, IR luminescence, and magnetic protection of documents)</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                                        <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}