import React, { useRef, useState, useEffect, useContext } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Pagination, Autoplay, Navigation, EffectFade, Zoom, Keyboard } from "swiper";
import { gsap } from 'gsap';
import { LangContext } from '../../App';

import 'swiper/css/effect-fade'
import 'swiper/swiper.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/less/grid';
import 'swiper/css/zoom';
import 'swiper/css/keyboard';

import { FaTelegram, FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
import printimage from "./imges/PrintedProducts.png"
import sceneinvestigation from "./imges/sceneinvestigation.png"
import CyberSecurity from "./imges/CyberSecurity.png"
import DigitalForensics from "./imges/DigitalForensics.webp"
import fingerprintDetection from "./imges/fingerprintDetection.png"
import ToolMarks from "./imges/ToolMarks.png"
import InspectionDetection from "./imges/Inspection&Detection.png"
import LaserScanningservices from "./imges/LaserScanningservices.png"
import Opensourceintelligence from "./imges/OpensourceIntelligence.webp"
import RoadSafetyQatar from "./imges/RoadSafetyQatar.png"
import imgpartners1 from './imges/partners/Acelabs.png'
import imgpartners2 from './imges/partners/ACMEPortablecomputer.png'
import imgpartners3 from './imges/partners/AnalitiKimya.png'
import imgpartners4 from './imges/partners/Attestor.jpg'
import imgpartners5 from './imges/partners/Cellebrite.jpg'
import imgpartners6 from './imges/partners/Doculus Lumus.jpg'
import imgpartners7 from './imges/partners/EMT forensics.png'
import imgpartners8 from './imges/partners/Everspry.png'
import imgpartners9 from './imges/partners/FARO.jpg'
import imgpartners10 from './imges/partners/Foster+Freeman.png'
import imgpartners11 from './imges/partners/Magnet Forensics.png'
import imgpartners12 from './imges/partners/Mobiledit.png'
import imgpartners13 from './imges/partners/Nuance Forensics.png'
import imgpartners14 from './imges/partners/Regula.png'
import imgpartners15 from './imges/partners/Oxygen Forensics.png'
import imgpartners16 from './imges/partners/Salvation Data forensics.png'
import imgpartners17 from './imges/partners/SceneSafe.jpg'
import imgpartners18 from './imges/partners/Sirchie.png'
import imgpartners19 from './imges/partners/Weiss AG.png'
import imgpartners20 from './imges/partners/Zarbeco US.jpg'
import imgpartners21 from './imges/partners/opentext.png'
import imgpartners22 from './imges/partners/projectina.png'


export default function Landing() {
    const langContext = useContext(LangContext);
    const lang = langContext.lang;

    const [activeIndex, setActiveIndex] = useState(0);
    const firstPic = useRef();
    useEffect(() => {
        gsap.fromTo(firstPic.current, { scale: "1" }, { scale: "1.1", duration: "3" });
        setActiveIndex(activeIndex + 1);
    }, [])

    const slideImgs = ['./img/landing/slide2/4.jpg',
        './img/landing/slide2/5.jpg',
        './img/landing/slide2/6.jpg',
        './img/landing/slide2/7.jpg',
        './img/landing/slide2/8.jpg',
        './img/landing/slide2/9.jpg',
        './img/landing/slide2/10.jpg',
        './img/landing/slide2/3.png',
        './img/landing/slide2/1.png'
    ];
    const onLern = () => {
        // alert("afjlkasfads");
    }

    return (
        <div className="w-full">
            <Swiper
                speed={600}
                parallax={true}
                loop={true}
                keyboard={{ enabled: true, onlyInViewport: true }}
                modules={[Parallax, Pagination, EffectFade, Zoom, Keyboard, Navigation, Autoplay]}
                slideToClickedSlide={true}
                className="relative bg-white items-content justify-center flex xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]"
                onSlideChange={(e) => {
                    setActiveIndex(e.realIndex);
                }}
                style={{
                    '--swiper-pagination-color': 'white',
                    '--swiper-pagination-bullet-size': '8px',
                    '--swiper-pagination-right': 'auto',
                    '--swiper-pagination-left': '10px',
                    '--swiper-pagination-top': 'auto',
                    '--swiper-pagination-bottom': '0px',
                    '--swiper-pagination-bullet-inactive-opacity': '0.8',
                    '--swiper-pagination-bullet-opacity': '1',
                    '--swiper-pagination-bullet-horizontal-gap': '12px',
                    '--swiper-pagination-bullet-inactive-color': '#000',
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={{
                    nextEl: '#nextbutton',
                    prevEl: '#prevbutton',
                }}
                effect="fade"
                fadeEffect={{ crossFade: true }}
                zoom={{ maxRatio: 1.5 }}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: true,
                }}
            >
                <div className='relative ml-40 lg:block hidden -mt-96 z-10 text-stone-50 float-left '>
                    <a href='https://www.linkedin.com/company/alzomar/' className='text-xl'>
                        <FaFacebook className='my-4 z-20 hover:scale-125 hover:text-sky-300' />
                    </a>
                    <a href='https://www.linkedin.com/company/alzomar/' className='text-xl my-4'>
                        <FaLinkedin className='my-4 z-20 hover:scale-125 hover:text-sky-300' />
                    </a>
                    {/* <a className='text-xl my-4'>
                        <FaYoutube className='my-4 z-20 hover:scale-125 hover:text-sky-300' />
                    </a> */}
                    <a href="https://wa.me/+97433296407" className='text-xl my-4'>
                        <FaWhatsapp className='my-4 z-20 hover:scale-125 hover:text-sky-300' />
                    </a>
                    {/* <a className='text-xl my-4'>
                        <FaTwitter className='my-4 z-20 hover:scale-125 hover:text-sky-300' />
                    </a> */}
                </div>
                <SwiperSlide key={0} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full items-content justify-center flex'>
                        {/* <img className={`relative ${activeIndex == 0 && "img_zoomUp"} w-full xl:h-screen lg:h-[800px] md:h-[400px] sm:h-[500px] h-[300px]`} src="../../img/logos.gif" />
                    */}
                        <img
                            className={`relative w-full xl:h-screen lg:h-[800px] md:h-[400px] sm:h-[500px] h-[300px]`} src="./img/landing/slide1/logos.gif" />




                    </div>
                    <div className='relative w-full bg-black bg-opacity-30 text-center'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className="xl:w-4/5 w-full">
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 max-sm:text-xl max-sm:my-2'>Alzomar</h1>
                                    <div className="text-center items-content justify-center flex">
                                        <h1 className='w-4/5 text-[16px] text-center text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar is based in Doha, Qatar, from where it serves many government and private organizations with a comprehensive range of products and services since 2002</h1>
                                    </div>
                                    <div className="items-content justify-center flex mt-2">
                                        <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>The success of the company is based on continuous introduction and delivery of the latest technologies and products in its specialist fields</h1>
                                    </div>
                                    {/* <div className="items-content justify-center flex mt-2 ">
                                        <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>We have partnered with leaders in the fields of security, communications, forensics, Biometrics as well as information and communication technology solutions that are focused on delivering amazing to the State of Qatar through delivery, dependency and excellence</h1>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={1} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 1 && "img_zoomUp"} w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]`} src={DigitalForensics} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className=' w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Digital Forensics Qatar</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4 ">
                                            <a href="/digital-forensics" className='text-stone-50'>Learn More</a>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>At Alzomar, we offer cutting-edge digital forensics solutions in Qatar, specializing in recovering, analyzing, and preserving critical electronic evidence to support legal investigations and cybersecurity efforts.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={2} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 2 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={Opensourceintelligence} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className=' w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Opensource Intelligence</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar delivers advanced open-source intelligence (OSINT) solutions in Qatar, leveraging publicly available data to provide actionable insights, support investigations, and enhance security measures for our clients.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={3} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 3 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={sceneinvestigation} />
                    </div>

                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Crime Scene Investigation Solutions</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <a href='/fingerprint/latent-fingerprint-detection' className='text-stone-50'>Learn More</a>

                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>At Alzomar we provide comprehensive crime scene investigation solutions and CSI-related consumables in Qatar. Our products and services support forensic teams with high-quality tools and materials essential for effective evidence collection.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={4} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 4 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={printimage} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>3D Printers and Additive Manufacturing</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar’s specialization in 3D printing and manufacturing solutions offers innovative and customized services to transform concepts into tangible products with precision, efficiency, and cutting-edge technology.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={5} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 5 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={fingerprintDetection} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Fingerprint Forensics</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>At Alzomar, we offer specialized forensic fingerprint detection solutions in Qatar, providing advanced technology and expertise to accurately identify and analyze fingerprints, crucial for solving criminal investigations and ensuring justice.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={6} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 6 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={InspectionDetection} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Forensic Inspection and Detection</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>We are your trusted partner for forensic inspection, detection, and screening solutions in Qatar. We deliver advanced tools and expertise to ensure thorough and accurate forensic analysis for criminal investigations and security assessments.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={7} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 7 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={ToolMarks} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Tool Marks and Weapons Investigation</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar delivers expert firearms, ballistics, and toolmarks investigation solutions in Qatar. Our advanced technology and specialized services support precise analysis and evidence evaluation, crucial for criminal investigations and forensic examinations.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={8} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 8 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={RoadSafetyQatar} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Public and Road Safety</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar offers comprehensive road safety solutions in Qatar, focusing on innovative technologies and strategies to enhance traffic management, reduce accidents, and promote safer driving environments across the region.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={9} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 9 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={LaserScanningservices} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>3D Laser Scanners</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>Alzomar provides cutting-edge 3D laser scanning solutions in Qatar, delivering precise and detailed 3D models for a range of applications, from construction and engineering to forensic analysis and cultural heritage preservation using LiDAR Scanners and latest photogrammetry technologies.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={10} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px]' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 10 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src={CyberSecurity} />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className=' md:ml-28 lg:ml-48'>
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Cyber Security</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4 ">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4 ">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>We are the premier cybersecurity solution provider in Qatar, offering robust and tailored protection against digital threats. Our expert team delivers cutting-edge security solutions to safeguard your data, infrastructure, and business continuity.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide key={11} className='relative w-full xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] ' data-aos='fade-in'>
                    <div className='absolute w-full'>
                        <img className={`relative ${activeIndex == 11 && "img_zoomUp"} w-full lg:h-screen md:h-[500px] sm:h-[500px] h-[300px]`} src="./img/landing/slide1/document-digitization-com.jpg" />
                    </div>
                    <div className='relative w-full bg-black bg-opacity-60'>
                        <div className='w-full relative code-block code-block-52 items-content justify-center flex'>
                            <div className=" w-4/5 xl:h-screen lg:h-[800px] md:h-[500px] sm:h-[500px] h-[300px] items-center justify-center flex">
                                <div className="xl:w-3/4">
                                    <h1 className='lg:text-[20px] text-[30px] text-stone-200 my-6 max-sm:text-xl max-sm:my-2'>Micro Website</h1>
                                    <h1 className='mb-5 lg:text-7xl text-5xl font-extrabold text-stone-200 my-2 max-sm:text-xl max-sm:my-2'>Questioned Document Examination</h1>
                                    <button className='w-56 items-center justify-center  transition duration-300 my-2 mb-10 text-xl font-bold hover:bg-[#7a1548] bg-opacity-20 border-2 hidden md:flex lg:mb-20' onClick={onLern}>
                                        <div className="w-3/4">
                                            <span className='text-stone-50'>Learn More</span>
                                        </div>
                                        <div className="w-1/4">
                                            <button className='bg-[#7a1548] transition duration-300 py-2 my-1'><div className='text-2xl px-2 text-stone-50'><GoArrowRight /></div></button>
                                        </div>
                                    </button>
                                    <h1 className='w-4/5 text-[16px] text-stone-50 max-sm:text-sm max-sm:my-2'>The increasing level of sophistication and quality of fraudulent documents, requires extensive and detailed examination of document security features. Discover the world’s leading technologies for authenticating identity documents, banknotes and securities.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className=" relative -mt-24 z-20 flex justify-end"
            >
                <div className='  '>
                    <button className=' text-stone-50 border-2 mx-2 text-center text-2xl hover:bg-[#7a1548] transition-all duration-200
            sm:h-8 sm:w-8 sm:text-sm sm:hover:w-10 sm:hover:h-10 sm:hover:text-xs 
            md:h-12 md:w-12 md:text-2xl md:hover:w-16 md:hover:h-12 md:hover:text-3xl' id="prevbutton">


                        <GoArrowLeft className='float-left' />
                    </button>
                </div>
                <div className=''>
                    <button className=' text-stone-50 border-2 mx-2 text-center text-2xl hover:bg-[#7a1548] transition-all duration-200
            sm:h-8 sm:w-8 sm:text-sm sm:hover:w-10 sm:hover:h-10 sm:hover:text-xs 
            md:h-12 md:w-12 md:text-2xl md:hover:w-16 md:hover:h-12 md:hover:text-3xl' id="nextbutton">

                        <GoArrowRight className='float-right' />
                    </button>
                </div>
            </div>




            <div className="w-full items-content justify-center flex mt-10">
                <div className="xl:w-full lg:w-full md:w-9/12 sm:w-10/12 w-11/12 py-20 items-content justify-center flex">
                    <div className="w-full lg:flex block ">
                        <div className="lg:w-1/2 w-full px-4">
                            <img src="../../img/about/vmg.jpg" className='rounded-lg' />
                        </div>
                        <div className="lg:w-full w-full text-gray-500 px-4">
                            <span className="text-3xl">Mission</span><br />
                            <span>Our mission is to advance forensic technology across Qatar and the Middle East by serving as a key distributor for the world's leading vendors in the field. We specialize in providing cutting-edge solutions in every branch of forensics, including:

                                <ul className="list-disc list-inside space-y-2 px-4">
                                    <li>Forensic Science: Offering state-of-the-art analytical tools and technologies.</li>
                                    <li>Digital Forensics: Delivering advanced solutions for data recovery and investigation.</li>
                                    <li>Ballistics and Firearms: Providing precision instruments for firearms and ballistics analysis.</li>
                                    <li>Questioned Document Examination: Supplying tools for accurate document verification and fraud detection.</li>
                                    <li>Crime Scene Investigation: Equipping teams with the latest technology for evidence collection and analysis.</li>
                                    <li>Road Safety: Implementing innovative solutions to enhance traffic management and accident prevention.</li>
                                    <li>Cybersecurity: Ensuring robust protection with cutting-edge cybersecurity measures.</li>
                                </ul>

                                We are dedicated to exceeding expectations by integrating these top-tier technologies to support law enforcement, regulatory compliance, and public safety throughout the region.
                            </span>
                            <br /><br />
                            <span className="text-3xl">Vision</span><br />
                            <span>Our vision is to lead forensic innovation in Qatar and the Middle East, delivering cutting-edge solutions across all forensic branches to enhance security, support law enforcement, and ensure public safety.</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="w-full items-content justify-center flex mb-36">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 items-center justify-center">
                    <div className=" w-full items-center justify-center flex border-b border-gray-300 pl-3 pb-3 mt-32 mb-16">
                        <div className="w-1/2">
                            <div>
                                <span className="italic text-sky-800 font-bold">{lang.UPDATE}</span>
                            </div>
                            <div>
                                <span className="xl:text-5xl lg:text-5xl text-4xl font-bold">LATEST NEWS</span>
                            </div>
                        </div>
                        <div className="w-1/2 flex flex-col items-end justify-end">
                            <a href="#" className="hover:scale-110 duration-300 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-[#7a1548] hover:bg-[#7a1548] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#7a1548] dark:hover:bg-[#7a1548] dark:focus:ring-[#4061a8]">
                                All Views
                            </a>
                        </div>
                    </div>
                    <div className="w-full items-center justify-center">
                        <div className="w-full mx-auto flex lg:grid-cols-3 lg:gap-x-3 md:grid-cols-2 sm:grid-cols-1">
                            <div className="w-full items-content justify-center flex">
                                <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 text-sm leading-6">
                                    <li>
                                        <a href="/digital-twin-2023" className="w-full lg:pb-5 sm:pb-5 pt-2 bg-white">
                                            <img className="object-fill h-48 w-96 grayscale hover:grayscale-0 transition duration-300 relative" src="./img/news1.jpg" />
                                        </a>
                                        <div className="pl-10 pt-5">
                                            <h3 className="">
                                                <a href="#" className="font-bold text-sm"> DIGITAL TWIN 2023 </a>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="/ajman-police-exhibition-2023" className="w-full lg:pb-5 sm:pb-5 pt-2 bg-white">
                                            <img className="object-fill h-48 w-96 grayscale hover:grayscale-0 transition duration-300" src="./img/news2.jpg" />
                                        </a>
                                        <div className="pl-10 pt-5">
                                            <h3 className="">
                                                <a href="#" className="font-bold text-sm"> AJMAN POLICE EXHIBITION 2023 </a>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="/world-police-summit-2023" className="w-full lg:pb-5 sm:pb-5 pt-2 bg-white">
                                            <img className="object-fill h-48 w-96 grayscale hover:grayscale-0 transition duration-300" src="./img/news3.jpg" />
                                        </a>
                                        <div className="pl-10 pt-5">
                                            <h3 className="">
                                                <a href="#" className="font-bold text-sm"> WORLD POLICE SUMMIT 2023 </a>
                                            </h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full mt-10 flex flex-col items-center justify-center">
                            <a href="#" className="hover:scale-110 duration-300 inline-flex items-center px-7 py-2.5 text-sm font-medium text-center text-white bg-[#7a1548] hover:bg-[#7a1548] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#7a1548] dark:hover:bg-[#7a1548] dark:focus:ring-[#4061a8]">
                                Load More
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="w-full">
                <div className='text-5xl font-bold text-center my-12'>
                    <a href="#" className=" text-2xl border-gray-500 py-2 border-b-2">OUR PARTNERS</a>
                </div>
                {/* <Swiper
                    speed={6000}
                    slidesPerView={7}
                    parallax={true}
                    loop={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    modules={[Parallax, Keyboard, Autoplay]}
                    slideToClickedSlide={true}
                    className="relative bg-white"
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: true,
                    }}
                >
                    <SwiperSlide key={0} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners1} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={1} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners2} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={2} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners3} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={3} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners4} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={4} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners5} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={5} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners6} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={6} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners7} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={7} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative w-56 h-56' src={imgpartners8} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={8} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners9} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={9} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners10} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={10} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners11} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={11} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners12} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={12} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners13} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={13} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners14} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={14} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners15} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={15} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners16} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={16} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners17} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={17} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners18} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={18} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners19} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={19} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners20} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={20} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners21} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={21} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={imgpartners22} />
                        </div>
                    </SwiperSlide>
                </Swiper> */}
                <Swiper
                    speed={6000}
                    slidesPerView={7}
                    parallax={true}
                    loop={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    modules={[Parallax, Keyboard, Autoplay]}
                    slideToClickedSlide={true}
                    className="relative bg-white"
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: true,
                    }}
                >
                    {[imgpartners1, imgpartners2, imgpartners3, imgpartners4, imgpartners5, imgpartners6, imgpartners7, imgpartners8, imgpartners9, imgpartners10, imgpartners11, imgpartners12, imgpartners13, imgpartners14, imgpartners15, imgpartners16, imgpartners17, imgpartners18, imgpartners19, imgpartners20, imgpartners21, imgpartners22].map((imgSrc, index) => (
                        <SwiperSlide key={index} className="relative" data-aos="fade-in">
                            <div className="relative">
                                <img
                                    className="relative w-[80%] aspect-[4/3] object-contain mix-blend-color-burn"
                                    src={imgSrc}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>
            {/* <div className="w-full">
                <div className='text-5xl font-bold text-center my-12'>
                    <a href="#" className=" text-2xl border-gray-500 py-2 border-b-2">OUR CLIENTS</a>
                </div>
                <Swiper
                    speed={6000}
                    slidesPerView={7}
                    parallax={true}
                    loop={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    modules={[Parallax, Keyboard, Autoplay]}
                    slideToClickedSlide={true}
                    className="relative bg-white cursor-auto"
                    autoplay={{
                        delay: 10,
                        disableOnInteraction: true,
                    }}
                >

                    <SwiperSlide key={0} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[0]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={1} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[1]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={2} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[2]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={3} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[3]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={4} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[4]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={5} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[5]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={6} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[6]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={7} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[7]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={8} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[8]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={9} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[8]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={10} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[0]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={11} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[1]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={12} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[2]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={13} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[3]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={14} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[4]} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={15} className='relative' data-aos='fade-in'>
                        <div className='relative'>
                            <img className='relative' src={slideImgs[5]} />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div> */}
        </div >
    )
}