import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Crime-lite_42S.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® 42S</h2>

                    <p class="mb-4">The first high-intensity dual-colour forensic Crime-lite for crime scene and laboratory applications.</p>

                    <p class="mb-4">
                        The Crime-lite® 42S, switchable between two arrays of 8 high-intensity LEDs, provides the forensic examiner with three illumination outputs in a single alternate light source. Using a simple 3-way control, the examiner is able to alternate between two narrowband wavelengths or use all 16 LEDs in combination for high-intensity wideband illumination. This ability to detect evidence under a selection of wavebands from a single source increases the chances of locating evidence and reduces search times for forensic investigators.
                    </p>



                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Features and Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Unique 16 LED dual-colour light sources:</strong> Switch between different wavelengths with ease.</li>
                        <li><strong>Switch between wavebands at the touch of a button:</strong> Simple controls for flexibility.</li>
                        <li><strong>Adjustable light intensity:</strong> Choose from 10%, 30%, 50%, or 100% intensity settings.</li>
                        <li><strong>Constant light output throughout battery charge:</strong> Consistent performance for the entire charge duration.</li>
                        <li><strong>Robust high specification construction:</strong> Built for durability and reliability.</li>
                        <li><strong>Supplied with twin-filter viewing goggles:</strong> Enhance visibility of evidence under different light sources.</li>
                        <li><strong>16 x high-efficiency LEDs:</strong> Includes 8 of each wavelength.</li>
                        <li><strong>Runs on AC mains or rechargeable battery:</strong> Flexible power options for different use cases.</li>
                        <li><strong>Battery runtime up to 600 minutes:</strong> Long-lasting performance for extended use.</li>
                        <li><strong>Low battery indicator:</strong> Alerts when battery power is low.</li>
                    </ul>

                   

                    <h3 class="font-semibold text-lg mb-2">Available Wavelength Combinations:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>White (400-700nm) & Blue (420-470nm)</li>
                        <li>UV (350-380nm) & Violet (395-425nm)</li>
                        <li>Blue (420-470nm) & Blue/Green (445-510nm)</li>
                        <li>Blue (420-470nm) & Green (480-560nm)</li>
                        <li>Blue (420-470nm) & Red (600-660nm)</li>
                        <li>Green (480-560nm) & Orange (570-610nm)</li>
                        <li>UV (350-380nm) & IR (800-900nm)</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                  <h3 class="font-semibold text-lg mb-2">Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Crime-lite Beyond Visible Search Kit</li>
                        <li>Anti-glare Viewing Goggles and Camera Filters</li>
                        <li>Evidence Viewing Box</li>
                        <li>Laboratory Arm</li>
                        <li>Laboratory Cart</li>
                        <li>Crime Scene Accessories</li>
                        <li>Power Supply Components</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}