import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochure/DCS5_Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">DCS®5</h2>

                    <p class="mb-4">UV | VIS | IR<br />No compromise fingerprint imaging workstation for unrivalled results. Used by fingerprint experts across the globe, DCS®5 is a comprehensive imaging system for the illumination, visualization, photography, and enhancement of almost any type of fingerprint on any surface or background, ensuring that maximum detail is revealed.</p>

                    <h3 class="font-semibold text-lg mb-2">World Class Fingerprints</h3>

                    <p class="mb-4">Representing best-in-class technology, the DCS®5 is our flagship system for the illumination, visualization, photography, and enhancement of fingerprints. Released at the turn of the millennium, the original DCS 121 represented a revolution in fingerprint photography.</p>

                    <p class="mb-4">By the late 1990s digital photography had become commercially ubiquitous, providing photographers with a quick and easy method of recording images that could be stored in a computerized file format or printed on demand. However, many forensic photographers were hesitant to adopt the new technology, fearing that digital images would be inferior in quality and more susceptible to manipulation compared with film.</p>

                    <p class="mb-4">At the turn of the millennium, foster+freeman, in close collaboration with Wiltshire Constabulary, launched the DCS 121, a groundbreaking digital photography system including tamperproof image validation software: a revolution had begun.</p>

                    <p class="mb-4">Over the last two decades, the DCS system has evolved into its current iteration, the DCS®5: a truly potent weapon in the hands of a fingerprint expert.</p>

                    <p class="mb-4">Designed to achieve court-ready results in the most challenging of situations, and capable of working across the spectrum, from IR to UV, the DCS®5 system offers solutions to forensic professionals for whom compromise is not an option. It can be found in high-profile fingerprint laboratories worldwide.</p>

                    <h3 class="font-semibold text-lg mb-2">DCS®5 Image Capture</h3>

                    <p class="mb-4">The DCS®5 system combines Nikon’s full-frame flagship camera, the D6, with application-specific lenses to provide the examiner with maximum control and deliver images of exceptional clarity across the spectrum.</p>

                    <h3 class="font-semibold text-lg mb-2">DCS®5 Illumination</h3>

                    <p class="mb-4">The DCS®5 system offers formidable illumination capabilities to enable the examiner to achieve exceptional results, with three additional specialized illumination modules also available.</p>

                    <h3 class="font-semibold text-lg mb-2">DCS®5 Software</h3>

                    <p class="mb-4">An integral part of DCS 5 is the easy-to-use software that provides sophisticated image processing and enhancement tools to uncover hidden detail within images.</p>

                    <h3 class="font-semibold text-lg mb-2">DCS®5 Imaging Techniques</h3>

                    <h4 class="font-semibold text-md mb-1">Fluorescence Imaging</h4>
                    <p class="mb-4">Many latent fingerprint treatments such as Ardrox, DFO, BY40, and fluorescent powders fluoresce under specific wavelengths of light. Using the DCS®5 multi-wavelength ring light, an operator can select the optimum wavelength to generate the maximum intensity of fluorescence for each type of treatment.</p>

                    <h4 class="font-semibold text-md mb-1">Infrared Imaging</h4>
                    <p class="mb-4">Interfering backgrounds may be suppressed by appearing white as they reflect IR, whereas some chemical treatments, such as Physical Developer and certain powders, absorb IR and appear black. foster+freeman’s fingerprint dusting powder, fpNatural 1, fluoresces brightly in the infrared when illuminated with red light, eliminating interference from most backgrounds that rarely fluoresce at this wavelength.</p>

                    <h4 class="font-semibold text-md mb-1">Reflected Longwave UV Imaging</h4>
                    <p class="mb-4">Many surfaces such as glass and plastics absorb UV and appear black, improving the visibility of both treated and untreated latent prints which often reflect UV. Images can then be significantly enhanced using the DCS®5 software.</p>

                    <h4 class="font-semibold text-md mb-1">Cylindrical Surface Unwrapping</h4>
                    <p class="mb-4">The DCS®5 Cylindrical Surface Unwrapper (CSU) enables 2D images of fingermarks to be extracted from narrow cylindrical items including bullets, pens, batteries, glass vials, bottles, syringes, hand tools, and other similarly shaped objects.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}