import React from 'react'
import { TabGroup } from '@statikly/funk'

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    TABLEAU TX1
                    <br />In the lab, or in the field, the NEW Tableau Forensic Imager (TX1) acquires more data, faster, from more media types, without ever sacrificing ease-of-use or portability.
                    <br /><br />Successor to the Tableau TD3 and redesigned from the circuit board up, the TX1 is built on a custom Linux kernel, making it lean and powerful. Every component is hand-selected and tested to guarantee reliability and performance when conducting forensic imaging operations.
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">
                        The TX1 can forensically image a broad range of media, including PCIe and 10Gb Ethernet devices, and supports up to two active forensic jobs at a time (simultaneous imaging). When imaging, TX1 outputs to raw .DD and .dmg formats, .e01 (compressed), or .ex01 (compressed), and features extensive file system support (ExFAT, NTFS, EXT4, FAT32, HFS+).
                        <br /><br />TX1-S1 DRIVE BAY
                        <br />Adds two additional cable-less SATA/SAS destination drive connections to the TX1
                        <br />Compatible with 2.5” and 3.5” SATA/SAS drives
                        <br />Internal fan provides drive cooling
                        <br />Easy modular connection – Just slide to connect/disconnect from the TX1
                        <br />Included in the TX1 Kit!
                        <br /><br />SUPPORT UP TO FOUR DESTINATIONS PER SOURCE
                        <br />With the Drive Bay connected, the TX1 can output to four SATA/SAS, one USB 3.0 and one or more network shares. TX1 supports up to four destinations per source (1:4) with the ability to combine clone/image duplication and local/network destinations. Flexibility and power that save users’ time!
                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">
                        Forensic Imager – Every component, design decision, and feature of the TX1 was included to improve efficiency, in the lab or in the field. From the intuitive user interface, to broad media support, simultaneous imaging, 10 GbE, and more, TX1 will save time and resources.
                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}