import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Crime-lite 82L Pro.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® 82L PRO</h2>

                    <p class="mb-4">A unique light source providing low-angle surface illumination.</p>

                    <p class="mb-4">
                        Featuring 16 high-intensity LEDs mounted behind a cylindrical lens, the Crime-lite® 82L PRO produces a wide floor-level light beam ideal for detecting surface debris and shoe prints. Developed by foster+freeman, the device emits a ‘sheet’ of variable intensity white or UV light to reveal floor-level evidence. It is supplied with blue, green, and red clip-on filters to enhance contrast on colored backgrounds.
                    </p>



                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Features and Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Flat linear sheet of light:</strong> Perfect for surface illumination.</li>
                        <li><strong>Constant light output:</strong> Maintains brightness throughout the battery charge.</li>
                        <li><strong>Ergonomic design:</strong> Easy to handle and operate.</li>
                        <li><strong>Adjustable light intensity:</strong> Set at 10%, 50%, or 100%.</li>
                        <li><strong>16 x high-efficiency LEDs:</strong> Provides powerful illumination.</li>
                        <li><strong>19W LED output power:</strong> High-powered light for thorough examination.</li>
                        <li><strong>Runs on AC mains or rechargeable battery:</strong> Flexible power options.</li>
                        <li><strong>Battery runtime up to 600 minutes:</strong> Long-lasting performance for extended use.</li>
                        <li><strong>Low battery indicator:</strong> Alerts when the battery is running low.</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Telescopic Pole</li>
                        <li>Crime Scene Cases</li>
                        <li>Power Supply Components</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Available Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>White: 400-700nm</li>
                        <li>UV: 350-380nm</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}