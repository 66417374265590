import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className="text-lg text-gray-500">Independent Workstation</h2>
                    <h3 className="text-lg text-gray-500">Document Reader Regula 70X9</h3>
                    <p className="text-lg text-gray-500">
                        Regula 70X9 is an independent workstation for full-page data processing with a built-in computer, perfect for stationary use in limited workplace spaces.
                    </p>

                    <h4 className="text-lg text-gray-500">A Compact Device That Fits Everywhere</h4>
                    <p className="text-lg text-gray-500">
                        The small-sized Regula 70X9 doesn’t require a stationary computer to operate, making it a go-to solution for border protection counters, passport controls, police stations, registration offices, and other compact spaces. It automatically reads and verifies passports, IDs, visas, driver’s licenses, and other identification documents.
                    </p>

                    <h4 className="text-lg text-gray-500">Technologies Used</h4>
                    <ul className="text-lg text-gray-500">
                        <li>Automatic document type identification: Verify data from 12,000+ international documents using AI and ML.</li>
                        <li>Optical Character Recognition (OCR): Convert typed or printed text into digital data.</li>
                        <li>MRZ reading: Scan and cross-check data from machine-readable zones.</li>
                        <li>RFID reading: Verify documents equipped with Radio Frequency Identification (RFID) chips.</li>
                        <li>Processing graphic fields: Automatic search and crop of signature, fingerprint, and portrait to display as separate images.</li>
                        <li>Barcode reading: Recognize all kinds of barcodes to accelerate operations.</li>
                        <li>Document authenticity: Automatic authenticity verification of documents.</li>
                        <li>Smart UV: Compensation for external light during image capture in ultraviolet light.</li>
                        <li>Elimination of glare: Remove reflection from laminate and holograms in white and IR light.</li>
                    </ul>

                    <h4 className="text-lg text-gray-500">Unlimited Capabilities for Limited Spaces</h4>
                    <ul className="text-lg text-gray-500">
                        <li>4 USB ports for peripherals</li>
                        <li>LAN connection for networking via Ethernet</li>
                        <li>Video output for connecting an external monitor</li>
                        <li>A built-in Intel® Core™ with active fan heatsink, 8GB of RAM, and 120GB SSD</li>
                    </ul>

                    <p className="text-lg text-gray-500">
                        Despite being just 160 mm wide, the Regula 70X9 conducts a surprisingly wide range of document checks. It reads and authenticates ID documents such as passports, visas, driver's licenses, and more, while automating data entry. All processing occurs directly on the device, thanks to the built-in PC, making it an independent unit and saving space.
                    </p>

                    <p className="text-lg text-gray-500">
                        The Regula 70X9 is also flexible and can be easily upgraded to a forensic mini lab. The optional built-in USB Multi-Touch Monitor adds convenient visualization.
                    </p>

                    <p className="text-lg text-gray-500">
                        With four USB ports, you can connect additional devices like the Spectral luminescent magnifier Regula 4177-5 or Light Table Regula 4167S. Depending on the configuration, it can also read RFID and SmartCard chips.
                    </p>

                    <p className="text-lg text-gray-500">
                        The SDK included with the workstation enables integration into a larger infrastructure, allowing instant data transfer and synchronization with existing systems.
                    </p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}