import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/CL-MLPRO.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® ML PRO 2</h2>

                    <p class="mb-4">
                        The Crime-lite® ML PRO 2 is a semi-automated forensic screening tool designed for busy forensic laboratories. It provides a reliable solution for processing a broad range of evidence types, with a particular focus on biological evidence detection. The ML PRO 2 is an essential tool for forensic examiners handling a wide variety of forensic traces.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Key Evidence Detection:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>DNA & Body Fluids</li>
                        <li>Hairs & Fibres</li>
                        <li>Fingerprints & Impressions</li>
                        <li>Drugs & Narcotics</li>
                        <li>Gunshot Residues</li>
                        <li>Tool Marks</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Capture High-Resolution Images:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Full-spectrum high-resolution camera</li>
                        <li>Zoom capability for detailed photography</li>
                        <li>Document evidence with scientific-grade accuracy</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Advanced Forensic Capabilities:</h3>
                    <p class="mb-4">
                        The Crime-lite ML PRO 2 allows examiners to detect traces of evidence that are invisible to the human eye. Using multispectral UV/Vis/IR illumination, it reveals evidence across the full forensic spectrum, from ultraviolet to infrared.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Illumination and Filter Combinations:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Explore over 3,300 wavelength/filter combinations</li>
                        <li>Motorized camera filters for precise results</li>
                        <li>Pre-set bandpass filters for evidence-specific searches</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Capture Every Detail with Full-Resolution Image Capture:</h3>
                    <p class="mb-4">
                        Equipped with a precise 4MP scientific-grade camera system with 30x optical magnification, the ML PRO 2 ensures accurate close-up and wide area photography. It is supported by a focus-assist rangefinder and alignment LED to capture every crucial detail.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Streamline Your Workflow:</h3>
                    <p class="mb-4">
                        The ML PRO 2 simplifies your forensic examination workflow, from initial screening to comprehensive reporting. This tool is designed to maximize evidence detection, streamline processes, and ensure high-quality results.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Request a Demonstration:</h3>
                    <p class="mb-4">
                        Experience the capabilities of the ML PRO 2 firsthand. Our expert staff offer product demonstrations globally, giving you the opportunity to see the system in action. Contact us to request a demonstration or for further information.
                    </p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}