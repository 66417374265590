import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/AARI_Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Amino Acid Rapid Imager (AARI)</h2>

                    <p class="mb-4">Streamlined visualization and mark-up of fingermarks.</p>

                    <p class="mb-4">
                        The Amino Acid Rapid Imager (AARI) is a fully integrated detection and imaging system designed to dramatically increase the speed and accuracy of fingerprint visualization on porous and semi-porous evidence. AARI is equipped with intelligent AI Assist Ridge Detection software that reduces manual processing times from hours to mere minutes.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Fully Integrated Imaging Workstation:</strong> AARI is designed to meet the needs of high-demand fingerprint laboratories, providing a complete solution for illuminating and photographing high-resolution fingermark images.</li>
                        <li><strong>Easy-to-Use Interface:</strong> From placing a document on the examination bed to exporting a full report, AARI streamlines the entire fingerprint processing workflow.</li>
                        <li><strong>Pre-set and Manual Operation:</strong> Use pre-configured examination settings or operate manually to reveal and capture fingermarks treated with amino acid reagents such as:</li>
                        <ul class="list-disc ml-10">
                            <li>Ninhydrin</li>
                            <li>1,2-Indandione</li>
                            <li>DFO</li>
                            <li>Cyanoacrylate</li>
                            <li>Physical Developer</li>
                            <li>Vaporized Metal Deposition (VMD)</li>
                            <li>Silver Nitrate</li>
                            <li>Iodine</li>
                            <li>Oil Red O</li>
                        </ul>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Specifications:</h3>

                    <h4 class="font-semibold text-md mb-1">High-Resolution Image Capture</h4>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Image documents up to A4 size</li>
                        <li>61MP UV/Colour/IR camera</li>
                        <li>Imaging resolution up to 1460ppi</li>
                        <li>Automatic filter selection</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-1">Multi-Spectral Illumination</h4>
                    <ul class="list-disc ml-5 mb-4">
                        <li>5700K diffuse white light</li>
                        <li>365nm UV</li>
                        <li>520-530nm green light</li>
                        <li>860nm infrared light</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-1">Intelligent Software Features</h4>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Quick-start examination presets</li>
                        <li>AI-Assisted ridge detection for fast and precise processing</li>
                        <li>Manual or automatic print mark-up</li>
                        <li>Simplified reporting for easy documentation and sharing</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                   <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}