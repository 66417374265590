import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/Brochures.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">BWT-102 Bullet Recovery Water Tank</h2>

                    <p class="mb-4">
                        Our bullet recovery water tank provides an effective and high-performance solution for acquiring comparison bullets from different types of firearms, including rifles and pistols, for ballistic examinations.
                    </p>

                    <p class="mb-4">
                        BWT-102 Bullet Recovery Water Tank is the fastest system compared to conventional bullet recovery systems, such as shooting into cotton tubes and fleece layers. The system offers fast, safe, durable, and easy-to-use features to ballistic experts, along with accurate results.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Some Features of BWT-102:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li><strong>Easy Installation:</strong> BWT-102 can be easily installed. It is designed to be assembled on-site with three separate parts, which provides low logistics costs. If your agency needs to relocate the tank, it can easily be carried.</li>

                        <li><strong>Multiple Firing Capability and Cartridge Collecting Unit:</strong> Bullet recovery tanks are safe as they eliminate risks compared to shooting at sand, which may result in injuries due to ricochets. Our system is designed to collect cartridge casings ejected from the firearm, saving time from collecting fired cartridges. It is also capable of handling multiple shooting tasks.</li>

                        <li><strong>Interior Illumination & Bullet Collection Tray:</strong> The interior of the BWT-102 is perfectly illuminated, allowing operators to collect bullets in a well-lit environment. Bullets fired into the recovery tank can be easily collected using the fully automated Bullet Collection Tray system, within minutes.</li>

                        <li><strong>Healthy and Safe Environment for Operators:</strong> Operators can shoot in a clear, healthy environment. Harmful gases are filtered and exhausted through our air filtering system. The tank eliminates the risk of bullet deflection and ensures any accumulated gases are safely exhausted, preventing potential accidents.</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >             
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}