import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochures/EverILS Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Everspry Intelligent Light Source</h2>

                    <p class="mb-4">
                        EverILS is a hand-held portable lighting source that assists Crime Scene Investigators (CSIs) in discovering shoeprints and other valuable clues left at a crime scene. With its advanced technology and powerful features, EverILS offers a wide range of trace evidence detection capabilities, high brightness and a wide illumination area, and is accompanied by a specially designed app for on-site collection of shoeprints. EverILS is lightweight and portable, with an operating time of up to 7 hours without recharging, making it a valuable tool for CSI experts during crime investigations. Its high illumination uniformity and power ensure that even the most subtle traces of evidence can be detected, improving the efficiency and effectiveness of crime scene investigations.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Accompanied by a Specially Designed App:</h3>

                    <p class="mb-4">
                        EverILS is accompanied by a specially designed app that allows for the on-site collection of shoeprints. The app features advanced noise removal capabilities, automatically removing any background noise to ensure accurate and reliable results. This feature ensures that CSI experts can collect and analyze shoeprints on-site, making the investigation more efficient and reducing the time needed for analysis. The specially designed app also ensures that the collected data is organized and easy to manage, making it easier to analyze and present in court.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Wide Range of Trace Evidence Detection Capabilities:</h3>

                    <p class="mb-4">
                        EverILS boasts a wide range of trace evidence detection capabilities, including multiple wave light sources such as white, blue, and green lights. These advanced technologies enable the detection of various trace evidence including shoeprints, fingerprints, blood, and semen, making it a valuable tool for CSI experts during crime investigations. With this feature, CSI experts can easily identify and collect vital evidence from crime scenes, making the investigation more efficient and effective.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">High Brightness and a Wide Illumination Area:</h3>

                    <p class="mb-4">
                        EverILS offers high brightness and a wide illumination area, with a wide angle of white light reaching up to 60 degrees and an effective illumination distance of up to 15 meters. This feature ensures that CSI experts can easily locate and identify latent shoeprints left at crime scenes, regardless of the lighting conditions. High brightness and wide illumination area also make it easier to collect evidence, ensuring that no details are missed during the investigation.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >                                     <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}