import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./EverISSBrochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='font-bold'>

                        Everspry Intelligent Shoeprint Scanner
                    </p>
                    EverISS, is a state-of-the-art intelligent shoeprint inspection equipment designed for forensic investigations at crime scenes. With its advanced technology and powerful features, EverISS offers high-precision scanning capabilities, a wide range of applications, and a lightweight and portable design. EverISS is the first of its kind portable real-time scanner that scans flat shoeprints left at crime scenes on various surfaces. The scanner supports Wi-Fi and has a downloadable app on tablet devices, with an image scanning area (LW) of 365 x 160 mm, and an operating time of over 2 hours without recharging. Multiple scanning modes and high-precision capabilities make EverISS a valuable tool for CSI experts during forensic investigations at crime scenes.
                    <p className='font-bold'> Wide range of applications</p>
                    EverISS offers a wide range of applications, featuring multiple scanning modes to support the detection of visible and latent shoeprints on various surfaces. With this feature, CSI experts can easily detect shoeprints left at crime scenes, regardless of the surface. Multiple scanning modes ensure that the scanner is versatile and can be used in various settings
                    <p className='font-bold'> High-precision scanning capabilities</p>
                    EverISS boasts high-precision scanning capabilities, capturing images with a resolution of up to 1200 DPI and image distortion of less than 1%. This feature ensures that CSI experts can obtain clear and accurate images of shoeprints left at crime scenes, making it easier to identify suspects. With this high level of precision, the scanner is reliable and efficient, making it a valuable tool for crime scene investigations.
                    <p className='font-bold'> Lightweight and portable </p>
                    EverISS is lightweight and portable, with dimensions of 320 mm in length, 380 mm in width, and 168 mm in height. Weighing just 7 kg, the scanner is easy to transport and use in various settings. With its portability, CSI experts can use the scanner in the field, making it more efficient to capture shoeprints at the crime scene. The lightweight and portable design ensures that the scanner is also easy to store and transport between locations.

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">
                        >>Forensics – Shoeprint
                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <span className="text-lg text-gray-500">
                        >>Imaging Method
                        <br />Photographic
                        <br /><br />
                        >>Optical Distortion
                        <br />No distortion
                        <br /><br />
                        >>Lighting Type
                        <br />LED lighting: 8-sided flat light, 8-sided diffuse light
                        <br /><br />
                        >>Lighting Properties
                        <br />32 bulbs with color temperature 6,500 K, 200~240 LM
                        <br /><br />
                        >>Device Operation Method
                        <br />Wireless
                        <br /><br />
                        >>LED Operation Method
                        <br />Bluetooth 4.0
                        <br /><br />
                        >>Total Weight
                        <br />Device: 4 kg, battery charger: 0.6 kg, pad: 0.8 kg, packaging: 1 kg
                        <br /><br />
                        >>Imaging Area
                        <br />(LW) 38 x 16 cm
                        <br /><br />
                        >>Power Supply
                        <br />Adapter: 100-240 V ~ 50/60 HZ 1.6 A (input); DC 12 V 3 A (output)
                        <br />Charger: 8.4 V 2 A (output)
                        <br /><br />
                        >>Charge Time
                        <br />6 h
                        <br /><br />
                        >>Operation Time
                        <br />3 h
                        <br /><br />
                        >>Battery
                        <br />Lithium battery 18650, 3,400 mAh
                        <br /><br />
                        >>Overall Dimensions
                        <br />Open: (LWH) 57 x 57 x 43 cm
                        <br />Closed: (LWH) 30 x 30 x 52 cm
                    </span>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}