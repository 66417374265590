import React from "react";
import { Link } from "react-router-dom";

import  img from "./Crime-lite 2/Images/CL2-1-1024x832.jpg"
import  img1 from "./Crime-lite 42S/Images/cl-42s-hand-1024x683 (1).jpg"
import  img2 from "./Crime-lite 82L Pro/Images/CL82LPRO-4-3-1-1024x768.jpg"
import  img3 from "./Crime-lite 82S Pro/Images/82s-pro-side-hand-comp-1-1024x768.jpg"
import  img4 from "./Crime-lite AUTO/Images/8000HS_421-768x511.jpg"
import  img5 from "./Crime-lite Eye/Images/holding-CLeye.jpg"
import  img6 from "./Crime-lite LASER/Images/LASER-LCD-1016x1024.png"
import  img7 from "./Crime-lite ML Pro 2/Images/ml-evo-circles-3.jpg"
import  img8 from "./Crime-lite ML2/Images/ML2-in-use-1.jpg"
import  img9 from "./Crime-lite X/Images/CLX-with-app.jpg"
import  img10 from "./Crime-lite XL/Images/xl-man-using-682x1024.jpg"


export default function Forensic() {
    return (
        <div className="w-full xl:pt-28">
            <div className="w-full items-content justify-center flex bg-[#7a1548] py-7">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 flex">
                    <div className="w-1/2">
                        <span className="text-white text-xl">Forensic Light Sources</span>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <div className="text-white text-xs">
                            <a href="/">Home</a><span> > </span><a href="/"> Crime Scene Reconstruction</a><span> > </span><span className="text-white text-xs">Forensic Light Sources</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full items-content justify-center flex mb-5">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 py-12 items-content justify-center">
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite 2</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-lite2" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img1} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite 42S</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-lite42S" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img2} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite 82L Pro</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-lite82LPro" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                                <img src={img3} className="scale-75" />
                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite 82S Pro</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-lite82SPro" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img4} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite AUTO</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteAUTO" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img5} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite Eye</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteEye" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img6} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite LASER</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteLASER" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img7} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite ML Pro 2</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteMLPro2" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img8} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite ML2</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteML2" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img9} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite X</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteX" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:flex md:block">
                        <div className="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full border border-gray-300 flex pl-4 lg:pb-14 hover:shadow-lg transition duration-500">
                            <div className="w-1/3">
                            <img src={img10} className="scale-75" />

                            </div>
                            <div className="w-2/3 mt-5 pt-2">
                                <span className="text-xl">Crime-lite XL</span><br /><br />
                                <a href="/crime-scene-reconstruction/ForensicLightSources/Crime-liteXL" className="text-white bg-[#7a1548] font-medium rounded text-sm px-7 py-2.5 mr-2 mb-2 hover:border-2 border-blue-800">Read more</a>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}