import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Crime-lite-LASER.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® LASER</h2>

                    <p class="mb-4">
                        The Crime-lite® LASER provides intense, ultra-narrowband Class 4 LASER illumination in a portable light source. Available in Blue (447nm) and Green (520nm), this device is designed for the detection of forensic evidence including latent fingerprints, body fluids, and minute traces of substances.
                    </p>



                    <h3 class="font-semibold text-lg mb-2">Forensic Evidence Detection:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Latent & treated fingerprints</li>
                        <li>Body fluids (blood, saliva, semen)</li>
                        <li>Accelerants and GSR traces</li>
                        <li>Illicit drug residues</li>
                        <li>Other minute traces of forensic evidence</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Advantages of LASER Illumination:</h3>
                    <p class="mb-4">
                        Crime-lite LASER illumination provides precise fluorescence within a narrow 2nm bandwidth, exciting only the target evidence and avoiding unwanted background fluorescence. Powered by six Class 4 LASER diodes, both Blue and Green LASER systems focus intense light into a single point for maximum accuracy.
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Available Wavelengths:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Blue 447nm</li>
                        <li>Green 520nm</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>High-intensity Class 4 LASER output: Blue (447nm), Green (520nm)</li>
                        <li>Powerful despeckled and shadow-free divergent beam</li>
                        <li>High power-to-weight ratio with ergonomic handheld design</li>
                        <li>Operates on AC mains or battery power</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Safety Features:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Triple-interlocked Class 3B safety features:</li>
                        <ul class="list-disc ml-8">
                            <li>Passcode activation</li>
                            <li>Dead man's trigger safety switch</li>
                            <li>Mechanical beam shutter</li>
                        </ul>
                        <li>Self-test diagnostics ensure optimal performance</li>
                        <li>Dark adaptation goggles included for operator safety</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}