import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/MVCFFLEX-Range.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">MVC FFLEX RANGE: The Next Industry-Standard Fingermark Fuming Cabinet</h2>

                    <p class="mb-4">For more than 20 years, MVC® fuming cabinets have set the benchmark for performance and reliability as dependable workhorses in forensic laboratories worldwide. With a radical new design, the MVC® FFLEX M builds on that trusted technology by offering innovative new features that optimize results, increase laboratory efficiency, and provide complete end-to-end control of the fingermark development process.</p>

                    <h3 class="font-semibold text-lg mb-2">Applications</h3>

                    <ul class="list-disc pl-6 mb-4">
                        <li>Forensic Laboratory: Gold-standard cyanoacrylate development of fingerprints on non-porous surfaces.</li>
                        <li>Accommodates both traditional Cyanoacrylate and PolyCyano UV 1-stage glue/stain processes.</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Hardware</h3>

                    <ul class="list-disc pl-6 mb-4">
                        <li>825-litre, mid-size fuming cabinet</li>
                        <li>Modular construction</li>
                        <li>LCD touchscreen interface</li>
                        <li>Multi-point process monitoring</li>
                        <li>NEW Variable life filter cartridges</li>
                    </ul>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Features</h3>

                    <ul class="list-disc pl-6 mb-4">
                        <li>SMART Process Management for Optimized Results</li>
                        <li>End-to-End Monitoring and Measurement</li>
                        <li>Complete End-User Safety</li>
                        <li>Increased Efficiency for Improved Evidence Throughput</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Superior Fuming Results</h3>

                    <p class="mb-4">The MVC FFLEX M delivers optimized user features, safety, and economy, while remaining focused on achieving the best possible results. It sets the gold standard in laboratory fingerprint fuming for forensic evidence development.</p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}