import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/MINIfume brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">MINIfume: Mobile Cyanoacrylate Fuming Chamber</h2>

                    <p class="mb-4">MINIfume is a mobile cyanoacrylate fuming chamber designed for the development of fingerprints on non-porous surfaces such as metal, glass, and plastics. It offers the high quality and reliability of a laboratory fuming chamber in a portable unit, suitable for use at crime scenes or offices.</p>

                    <h3 class="font-semibold text-lg mb-2">Product Overview</h3>

                    <p class="mb-4">Cyanoacrylate (superglue) fuming is a method for developing fingerprints on non-porous surfaces. MINIfume is designed for those who need to develop small quantities of evidence quickly and reliably while on the go. When evidence can’t be brought to the laboratory, MINIfume allows the laboratory to come to the evidence. It is perfect for fuming smaller objects like knives, handguns, and bottles.</p>

                    <h3 class="font-semibold text-lg mb-2">Product Features</h3>

                    <h4 class="font-semibold text-md mb-1">Innovative Interior Design Concept</h4>

                    <p class="mb-4">The interior of the MINIfume chamber is made from high-quality durable stainless steel with a tempered safety glass viewing panel in the chamber door. This allows users to observe the evidence while it develops and makes the chamber easy to clean. All evidence rods and support shelving are constructed from stainless steel and can be rearranged or removed without any tools. They are also dishwasher-safe for easy decontamination.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Control System</h4>

                    <p class="mb-4">MINIfume features a multilingual color touch-panel display for controlling the chamber. Users can select pre-programmed cycles, customize and save cycle settings, or manually control each step of a cycle. The system allows for the adjustment of humidity, fuming temperature, and cycle step time, making it compatible with various cyanoacrylate developers, including uncommon or future developers.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Filter Management</h4>

                    <p class="mb-4">Like the larger MEGAfume models, MINIfume utilizes a high-quality low-dust carbon filter to remove cyanoacrylate after a fuming cycle. The system does not require external ventilation, and filters can be easily swapped out without tools. The advanced filter management program ensures that filters are only replaced when necessary, reducing costs and labor.</p>

                    <h3 class="font-semibold text-lg mb-2">Compact and Mobile Design</h3>

                    <p class="mb-4">MINIfume is housed in a durable rolling case, complete with a laser-cut foam storage insert for all accessories, making it highly portable and practical for transportation and storage. This makes it ideal for crime scene use and on-the-go evidence processing.</p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}