import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochure/DISCOVER_Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">DISCOVER Fingerprint Imaging System</h2>

                    <p class="mb-4">A truly versatile fingerprint imaging system designed to maximize the search, detection, and imaging of evidence at the crime scene and in the laboratory. Combining the handheld Crime-lite AUTO with a modular laboratory dock, DISCOVER is a versatile two-part workstation for every stage of the forensic evidence workflow.</p>

                    <h3 class="font-semibold text-lg mb-2">HARDWARE</h3>

                    <h4 class="font-semibold text-md mb-1">Laboratory Docking System</h4>

                    <ul class="list-disc list-inside mb-4">
                        <li>Crime-lite AUTO quick-dock port</li>
                        <li>XYZ positioning</li>
                        <li>Mouse/Keyboard/Monitor interface</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-1">Illumination & Imaging Modules</h4>

                    <ul class="list-disc list-inside mb-4">
                        <li>Cylindrical Surface Unwrapper</li>
                        <li>Coaxial Illumination</li>
                        <li>Polarised Illumination</li>
                        <li>Diffuse Light Plate</li>
                    </ul>



                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"


                >

                    <h3 class="font-semibold text-lg mb-2">FEATURES</h3>

                    <ul class="list-disc list-inside mb-4">
                        <li>Seamless transition between Crime Scene & Laboratory</li>
                        <li>Perform visible, fluorescent, contrast, UV, and IR examinations</li>
                        <li>Advanced software functionality</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Crime Scene to Crime Lab</h3>

                    <p class="mb-4">A uniquely versatile and cost-effective forensic imaging solution: DISCOVER takes the intuitive operation of the Crime-lite AUTO and applies it to laboratory fingerprint imaging.</p>

                    <h4 class="font-semibold text-md mb-1">Un-docked…</h4>

                    <p class="mb-4">The compact Crime-lite AUTO is a handheld search and imaging tool providing 55x wavebands of illumination for the detection of evidence such as fingermarks, body fluids (blood, semen, sweat), narcotics, accelerants, gunshot residues, and more.</p>

                    <h4 class="font-semibold text-md mb-1">Docked…</h4>

                    <p class="mb-4">The Crime-lite AUTO provides the illumination and capture system at the heart of the DISCOVER fingermark imaging workstation. Built to meet the demands of fingerprint photography experts, a selection of slot-in imaging and illumination modules offer application-specific tools for visualizing fingermarks on hard-to-image surfaces such as bullet casings, smartphone screens, mirrors, and other reflective surfaces.</p>

                    <h4 class="font-semibold text-md mb-1">At the Crime Scene…</h4>

                    <p class="mb-4">The Crime-lite AUTO equips investigators with the tools and confidence required to search for, detect, and capture high-quality images of evidence in-situ.</p>

                    <h4 class="font-semibold text-md mb-1">In the Forensic Laboratory…</h4>

                    <p class="mb-4">DISCOVER combines the entire feature-set of the Crime-lite AUTO with advanced functionality, including evidence-specific modules to capture and enhance the best possible images of fingerprints on a wide variety of surfaces.</p>


                    <h3 class="font-semibold text-lg mb-2">FEATURES</h3>

                    <h4 class="font-semibold text-md mb-1">Modular Design</h4>

                    <p class="mb-4">A selection of slot-in illumination and imaging modules provide fingerprint examiners with dedicated tools to achieve maximum print visibility on a range of substrates and surfaces:</p>

                    <ul class="list-disc list-inside mb-4">
                        <li>Diffuse Light Panel</li>
                        <li>Cylindrical Surface Unwrapper</li>
                        <li>Coaxial Light Box</li>
                        <li>Polarised Light Tunnel</li>
                        <li>Fluorescence Imaging Bellows</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-1">Software Features</h4>

                    <p class="mb-4">Gain access to a range of software features when using the DISCOVER system, including:</p>

                    <ul class="list-disc list-inside mb-4">
                        <li>Live video search and capture</li>
                        <li>Image mark-up & enhancements</li>
                        <li>Digital calipers</li>
                        <li>Audit trail</li>
                        <li>Report generation</li>
                        <li>Image stitching</li>
                        <li>Service log</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}