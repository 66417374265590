import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochure/CSL-Shoe Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <h2 class="font-bold text-xl mb-4">CSL-Shoe Crime Scene Light</h2>

                    <p class="mb-4">
                        The CSL-Shoe is a portable crime scene light designed specifically for the identification and recovery of shoe print evidence.
                        Thanks to its high-intensity LED array, the CSL-Shoe can identify and document sole patterns and markings on a variety of surfaces.
                        Its tough, lightweight construction, extended running time, and telescopic handle make it an integral part of every crime scene kit.
                    </p>

                    <p class="mb-4">
                        Due to the linear light emission, the shoe print is displayed sharply and with high contrast on various surfaces.
                        Thanks to the telescopic handle, the CSL-Shoe light source is extremely user-friendly.
                    </p>



                    <h3 class="font-semibold text-lg mb-2">Technical Specification:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li>Beam shape, linear, 350 mm width.</li>
                        <li>Continuous operation: in default mode approx. 120 min., in high power mode approx. 45 min.</li>
                        <li>Low battery indicator.</li>
                        <li>Storage battery: Lithium Ion, capacity 14.4 V, 4.3 Ah.</li>
                        <li>Universal battery charger, 100-240 VAC.</li>
                        <li>Weight (including storage battery): approx. 2.3 kg.</li>
                        <li>Dimensions: 373 mm width x 97 mm depth x 138 mm height.</li>
                    </ul>

                    <h3 class="font-semibold text-lg mb-2">Accessories:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li>Carrying case.</li>
                        <li>Power supply for permanent mains supply 100-240 VAC.</li>
                        <li>Telescopic arm for ergonomic work when securing of evidence.</li>
                        <li>Colour filter: red, green, blue, and yellow for contrast enhancement.</li>
                    </ul>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <h3 class="font-semibold text-lg mb-2">Key Features:</h3>

                    <ul class="list-disc ml-5 mb-4">
                        <li>55 W high intensity dimmable LED array provides a sharp and high contrast shoe print.</li>
                        <li>On-board colour filters for additional contrast enhancement.</li>
                        <li>Continuous operation: in default mode approx. 120 min., in high power mode approx. 45 min.</li>
                        <li>Ergonomic, rugged design with telescopic handle for ease of use on walls, roofs, etc.</li>
                    </ul>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}