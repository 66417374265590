import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochure/MEGAfume brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">MEGAfume Cyanoacrylate Fuming Chamber</h2>

                    <p class="mb-4">MEGAfume is the cyanoacrylate fuming chamber designed for the development of fingerprints on non-porous surfaces such as metal, glass, and plastics. This system is specifically developed for accredited laboratories requiring high levels of safety, quality, and cleanliness for this task.</p>

                    <h3 class="font-semibold text-lg mb-2">Product Overview</h3>

                    <p class="mb-4">Cyanoacrylate (superglue) fuming is a method used for developing fingerprints on non-porous surfaces. Our MEGAfume series comes in three sizes: the smaller benchtop S61, the medium floor-standing M61, and the extra-large XL61 for larger pieces of evidence that standard chamber sizes cannot accommodate.</p>

                    <h3 class="font-semibold text-lg mb-2">Product Features</h3>

                    <h4 class="font-semibold text-md mb-1">Innovative Interior Design Concept</h4>

                    <p class="mb-4">The MEGAfume chambers feature an external support frame with glass safety walls, giving users a 360° view of the evidence. The design allows for easy cleaning as internal structural components are minimal. Parts such as the water reservoir and circulation fan can be easily removed, and all evidence rods and support shelving are constructed from stainless steel for durability and DNA decontamination.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Control System</h4>

                    <p class="mb-4">The MEGAfume system is operated via a large multilingual color touch-panel display, offering pre-programmed cycle options for common developers or the ability to fully customize cycle settings. The control system allows adjustments for humidity, fuming temperature, and cycle step time to accommodate a wide variety of cyanoacrylate developers, including those not yet on the market.</p>

                    <h4 class="font-semibold text-md mb-1">USB Datalogger</h4>

                    <p class="mb-4">The MEGAfume system records all cycle information, whether pre-programmed or manually controlled, using a USB datalogger. The data can be downloaded for review and report generation, making it ideal for ISO 17025 accreditation. Users can also customize reports by adding notes and department logos.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Filter Management</h4>

                    <p class="mb-4">The MEGAfume system utilizes a low-dust carbon filter for removing cyanoacrylate after a cycle. The filter is highly effective at purging the chamber without requiring external ventilation and can be quickly swapped out. The system's filter management program prevents premature filter changes by estimating the actual usage of the filter, reducing costs and labor.</p>

                    <h4 class="font-semibold text-md mb-1">New Walk-in Model: MEGAfume XL61</h4>

                    <p class="mb-4">The new MEGAfume XL61 is a walk-in chamber designed for handling larger pieces of evidence such as bicycles. Featuring a nearly one-meter door aperture and a double-winged door, the chamber offers flexible shelfing and all the control and design features of the smaller MEGAfume models, making it an ideal tool for larger laboratory needs.</p>

                    <h3 class="font-semibold text-lg mb-2">Additional Accessories</h3>

                    <ul class="list-disc list-inside mb-4">
                        <li>Support system for long evidence such as bats and rifles</li>
                        <li>Pin support bracket and shelf system for smaller items like handguns and bottles</li>
                        <li>UV-C decontamination unit for DNA contaminants (compatible with the NINcha series)</li>
                        <li>Starter developer kit with cyanoacrylate, pans, evidence clips, and hanging hooks</li>
                        <li>Accessory box for storage under the MEGAfume M61 model</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h3 class="font-semibold text-lg mb-2">Product Features</h3>

                    <h4 class="font-semibold text-md mb-1">Innovative Interior Design Concept</h4>

                    <p class="mb-4">The MEGAfume chambers feature an external support frame with glass safety walls, giving users a 360° view of the evidence. The design allows for easy cleaning as internal structural components are minimal. Parts such as the water reservoir and circulation fan can be easily removed, and all evidence rods and support shelving are constructed from stainless steel for durability and DNA decontamination.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Control System</h4>

                    <p class="mb-4">The MEGAfume system is operated via a large multilingual color touch-panel display, offering pre-programmed cycle options for common developers or the ability to fully customize cycle settings. The control system allows adjustments for humidity, fuming temperature, and cycle step time to accommodate a wide variety of cyanoacrylate developers, including those not yet on the market.</p>

                    <h4 class="font-semibold text-md mb-1">USB Datalogger</h4>

                    <p class="mb-4">The MEGAfume system records all cycle information, whether pre-programmed or manually controlled, using a USB datalogger. The data can be downloaded for review and report generation, making it ideal for ISO 17025 accreditation. Users can also customize reports by adding notes and department logos.</p>

                    <h4 class="font-semibold text-md mb-1">Innovative Filter Management</h4>

                    <p class="mb-4">The MEGAfume system utilizes a low-dust carbon filter for removing cyanoacrylate after a cycle. The filter is highly effective at purging the chamber without requiring external ventilation and can be quickly swapped out. The system's filter management program prevents premature filter changes by estimating the actual usage of the filter, reducing costs and labor.</p>

                    <h4 class="font-semibold text-md mb-1">New Walk-in Model: MEGAfume XL61</h4>

                    <p class="mb-4">The new MEGAfume XL61 is a walk-in chamber designed for handling larger pieces of evidence such as bicycles. Featuring a nearly one-meter door aperture and a double-winged door, the chamber offers flexible shelfing and all the control and design features of the smaller MEGAfume models, making it an ideal tool for larger laboratory needs.</p>

                    <h3 class="font-semibold text-lg mb-2">Additional Accessories</h3>

                    <ul class="list-disc list-inside mb-4">
                        <li>Support system for long evidence such as bats and rifles</li>
                        <li>Pin support bracket and shelf system for smaller items like handguns and bottles</li>
                        <li>UV-C decontamination unit for DNA contaminants (compatible with the NINcha series)</li>
                        <li>Starter developer kit with cyanoacrylate, pans, evidence clips, and hanging hooks</li>
                        <li>Accessory box for storage under the MEGAfume M61 model</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}