import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div style={{backgroundColor: "#7a1548"}} className="bg-[#7a1548] w-full justify-center items-center flex">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 items-center justify-center pt-20 pb-32 flex sm:mx-10 flex-col sm:flex-row">
                    <div className="w-4/12">
                        <span className="font-cr text-white font-serif text-lg">QUICK LINKS</span>
                        <div className=" text-white mt-6">
                            <ul >
                                <li><a href="/about" className="transition duration-300 hover:text-white underline">About Us</a></li>
                                <li><a href="/about/awards" className="transition duration-300 hover:text-white underline">Awards</a></li>
                                <li><a href="/mission-vision" className="transition duration-300 hover:text-white underline">Mission &amp; Vision</a></li>
                                {/* <li><a href="/clients" className="transition duration-300 hover:text-white underline">Clients</a></li> */}
                                <li><a href="/partners" className="transition duration-300 hover:text-white underline">Partners</a></li>
                                <li><a href="/contact-us" className="transition duration-300 hover:text-white underline">Contact</a></li>
                            </ul>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div className="w-4/12">
                        {/* <span className="font-cr text-white font-serif text-lg">NEWS & EVENTS</span>
                        <div className=" text-white mt-6">
                            <ul>
                                <li className="mb-4">
                                    <a className="transition duration-300 hover:text-white underline" href="/digital-twin-2023">Digital Twin 2023</a><br />
                                    <span>March 14, 2023</span>
                                </li>
                                <li className="mb-4">
                                    <a className="transition duration-300 hover:text-white underline" href="/ajman-police-exhibition-2023">Ajman Police Exhibition 2023 </a><br />
                                    <span>March 13, 2023</span>
                                </li>
                                <li className="mb-4">
                                    <a className="transition duration-300 hover:text-white underline" href="/world-police-summit-2023">World Police Summit 2023</a><br />
                                    <span>March 7, 2023</span>
                                </li>
                                <li>
                                    <a className="transition duration-300 hover:text-white underline" href="/">view all</a>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                        </div> */}
                    </div>
                    <div className="w-4/12">
                        <span className="font-cr text-white font-serif text-lg ">CONTACT INFO</span>
                        <div className=" text-white mt-6">
                            <ul>
                                <li>Head Office</li>
                                <li>Alzomar Trading LLC</li>
                                {/* <li>(S.A.T. Trading L.L.C)</li> */}
                                <li>P.O. Box: 21282, Qatar</li>
                                <li>10th Floor, Office no. 2</li>
                                <li>Burj Marina Tower</li>
                                <li>Lusail, Qatar</li>
                                <li>Tel: +974 4432 1121 </li>
                                <li>Mob: +974 5507 8156</li>
                                <li>Fax: +974 4432 2028</li>
                                <li>Email: admin@alzomar.com; mosaed@alzomar.com</li>
                            </ul>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-neutral-200 p-3 text-center dark:bg-neutral-600">
                <span className='text-xs'>© Copyright 2023 ALZOMAR. All rights reserved.</span>
            </div>
        </footer>
    )
}