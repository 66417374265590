import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/CL-AUTO.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">Crime-lite® AUTO</h2>

                    <p class="mb-4">
                        Forensic imaging technology meets powerful multi-spectral illumination in this complete solution to the search, detection, and capture of evidence.
                    </p>

                    <p class="mb-4">
                        Investigators no longer need to carry multiple tools, as the Crime-lite® AUTO combines light sources, camera, filters, and goggles into one device. This portable and easy-to-use tool is essential for criminal investigators and first responders, revealing evidence like body fluids (semen, saliva, urine, etc.), blood on dark fabrics, fingerprints, gunshot residues, and traces of physical evidence (glass, fibers, hairs, etc.).
                    </p>

                    <h3 class="font-semibold text-lg mb-2">Specifications:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Complete all-in-one search and imaging device</li>
                        <li>Full-spectrum UV/Vis/IR camera</li>
                        <li>55x illumination wavebands</li>
                        <li>Automatic filter selection</li>
                        <li>Autofocus image/video capture</li>
                        <li>Simple touch panel interface</li>
                        <li>Intuitive software and companion app</li>
                    </ul>

                    

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                   <h3 class="font-semibold text-lg mb-2">Accessories:</h3>
                    <ul class="list-disc ml-5 mb-4">
                        <li>Interactive Evidence Sample Kit – Crime-lite® AUTO</li>
                        <li>Scene-of-Crime Tripod – Crime-lite® AUTO</li>
                        <li>Laboratory Arm – Crime-lite® AUTO</li>
                        <li>Oblique Ring Light – Crime-lite® AUTO</li>
                        <li>Coaxial Light Box – Crime-lite® AUTO</li>
                        <li>Power Accessories – Crime-lite® AUTO</li>
                        <li>SD Card & USB Memory Stick – Crime-lite® AUTO</li>
                        <li>Magnetic Lens Cap – Crime-lite® AUTO</li>
                        <li>Adjustable Neck/Shoulder Strap – Crime-lite® AUTO</li>
                        <li>Scene-of-Crime Carry Case – Crime-lite® AUTO</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}