import React from 'react'
import { TabGroup } from '@statikly/funk'

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    This is a 100% Contactless Fingerprint Imaging Requiring No Powders or Chemicals<br /><br />
                    The system operates without being affected by daylight, uses non-reflective VISIBLE Lights with 4 different wavelengths.
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        >>Designed for Crime Scenes & Laboratory use<br /><br />
                        >>Designed for Reflective Fingerprint and Hand print imaging
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        >>0°-85° Multispectral Spot Lighting function<br /><br />
                        >>Internal White/UV1/Blue/Cyan/ Green/Red Narrow band Led groups and one touch Led group switch<br /><br />
                        >>Darkroom feature for external light systems<br /><br />
                        >>Records images without using external lights between 95°-170° (oblique lighting), without being affected by outside light<br /><br />
                        >>For image sharpness, yellow optical coating filter, orange optical coating filter, red optical coating filter & optional specific filters<br /><br />
                        >>4 Different Narrow Band Led Groups for high coaxial imaging<br /><br />
                        >>Easy switching between the LED light groups with control buttons<br /><br />
                        >>No additional user calibration required<br /><br />
                        >>The power and intensity of the LED light groups<br /><br />
                        >>Environment Friendly<br />
                        >>Fast & contactless fingerprint imagining<br />
                        >>Does not require finger print powder, chemicals, smoke or respirable dust<br />
                        >>Secures working environment<br /><br />
                        >>Evidence Quality<br />
                        >>No chance of contaminating or destroying the DNA capability within the latent mark<br />
                        >>Does not affect or destroy the fingerprint itself allowing the untouched latent mark to be used for DNA analyses<br /><br />
                        >>Mobility<br />
                        >>Completely mobile due to high powered batteries<br />
                        >>Easy to handle, lightweight device<br />
                        >>The flexibility of taking the lab to the scene, not the scene to the lab
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}