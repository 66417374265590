import React from 'react'
import { TabGroup } from '@statikly/funk'

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    forensicGEM™ kits are customized for the applications found in leading forensic laboratories.Specifically formulated for saliva stains and buccal swabs. This fully automatable method proves DNA in 20 minutes that is suitable for all forensic profiling method. forensicGEM™ has been formulated for high-throughput DNA extraction from crime samples. The forensicGEM™ kits have been specifically designed to be compatible with high-multiplex STR profiling kits and all reagents are rigorously Quality Controlled to test for efficacy and for the absence of human DNA.
                    <br />ZyGEM technology is ideally suited to the large-scale batch processing of reference samples for forensic analysis. It is validated by ESR New Zealand, one of the largest forensics laboratories in the world, and forensicGEM™ is a rapid, high throughput processing technology.
                    <br /><br />All forensicGEM™ kits are rigorously tested at a leading forensic laboratory for efficacy and purity. The basis of all of the kits is the forensicGEM™ proteinase, but special formulations have been developed for different substrates. DNA extraction using forensicGEM™ Saliva.
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        >>Forensic – Crimescene
                        <br />>>Forensic – DNA & Biology
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                        >>forensicGEM™ is validated and quality tested for forensic applications.
                        <br /><br />>>The simplicity of the method maximizes yields of DNA from trace samples.
                        <br /><br />>>The kit’s optimized methods and formulations provide DNA in less than 30 minutes.
                        <br /><br />>>All buffers and reagents are PCR compatible with common profiling kits.
                        <br /><br />>>The methods are closed-tube and hands-off.
                        <br /><br />>>Closed–tube (protects sample integrity)
                        <br /><br />>>Hands–off (saves labor costs)
                        <br /><br />>>One-step (maximizes yields and so increases the chance of obtaining a usable profile)
                        <br /><br />>>Easily automated (compatible with most low cost, off-the-shelf liquid handling robots)
                        <br /><br />>>Validated for forensic analysis
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}