import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/NINcha P31 brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">NINcha P31: The Mobile Climate Chamber for Fingerprint Development</h2>
                    <p class="mb-4">The <strong>NINcha</strong> series is specially designed for developing fingerprints and similar marks on porous and semi-porous surfaces using developers like Ninhydrin, DFO, and Indandione. After treatment through dipping or spraying, evidence is developed in a climate chamber under ideal temperature and humidity conditions, ensuring the best results.</p>

                    <p class="mb-4">Unlike general-purpose chambers, NINcha systems are tailored for forensic use, developed based on feedback from professionals in the field. They provide modern standards of reliability and performance for fingerprint development, especially on porous surfaces. The <strong>NINcha P31</strong> model is a portable, suitcase-sized version of the larger NINcha models, making high-quality fingerprint development possible even outside the lab.</p>

                    <h3 class="font-semibold text-lg mb-2">Features</h3>

                    <h4 class="font-semibold text-md mb-2">Stainless Steel and Glass Interior</h4>
                    <ul class="list-disc pl-6 mb-4">
                        <li>The inner climate chamber is made from high-quality smooth stainless steel, making it resistant to corrosion and easy to clean.</li>
                        <li>Removes DNA contamination effortlessly with peroxide-based cleaners.</li>
                        <li>Racks inside can hold two A4-sized pieces of paper or multiple smaller pieces of evidence at once.</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-2">Mobility Without Compromise</h4>
                    <ul class="list-disc pl-6 mb-4">
                        <li>Built into a durable rolling case with an extendable handle for transportation and storage.</li>
                        <li>Maintains the same high evidence processing standards as larger models, even in the field.</li>
                        <li>Large glass viewing pane allows evidence to be observed during development.</li>
                        <li>Automatic locking chamber door to prevent accidental disturbance of evidence.</li>
                        <li>Equipped with the same datalogger system as larger NINcha models for quality assurance and documentation.</li>
                    </ul>

                    <h4 class="font-semibold text-md mb-2">Innovative Operation</h4>
                    <ul class="list-disc pl-6 mb-4">
                        <li>Controlled via a user-friendly multilingual touch-panel display.</li>
                        <li>Pre-programmed cycles for common developers like DFO, with options to customize or create entirely new cycles.</li>
                        <li>Displays all relevant parameters for easy monitoring during cycles.</li>
                        <li>Automatic and manual cycle control options, with data logged for future review or quality control.</li>
                        <li>Unique condensation control system to prevent water droplets from damaging evidence during development.</li>
                    </ul>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}