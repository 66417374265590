import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className="text-gray-500">Mobile Workstation Regula 7310</h2>
                    <h3 className="text-gray-500">A smartphone-sized portable device for identity verification with external fingerprint scanner</h3>
                    <p className="text-gray-500">
                        Professional identity document authenticity checks and biometric data capture wherever you are.
                    </p>

                    <h3 className="text-gray-500">An Advanced Verification Tool That Fits in One Hand</h3>
                    <p className="text-gray-500">
                        Regula 7310 is designed to enable thorough document authentication and biometric data capture in any place and on the go. The indestructible rugged body ensures performance in all conditions while conveniently fitting in the palm of your hand.
                    </p>

                    <p className="text-gray-500">
                        Proven by road police, the device instantly reads and verifies passports, IDs, driver’s licenses, and other identity documents.
                    </p>

                    <h3 className="text-gray-500">Instant Reading of ID Documents</h3>
                    <ul className="text-gray-500">
                        <li>Passport</li>
                        <li>Driver license</li>
                        <li>ID cards</li>
                        <li>Visas</li>
                        <li>ePassport</li>
                    </ul>

                    <h3 className="text-gray-500">Highlighted Features</h3>
                    <p className="text-gray-500"><strong>Reliable & easy to use</strong></p>
                    <ul className="text-gray-500">
                        <li>Reinforced body</li>
                        <li>Long-life rechargeable battery</li>
                        <li>MicroSD, 2 SIM, and USB Type-C ports</li>
                        <li>3G, 4G, Wi-Fi</li>
                        <li>External fingerprint scanner</li>
                    </ul>

                    <h3 className="text-gray-500">A Small Yet Powerful Verification Partner</h3>
                    <p className="text-gray-500">
                        Comprehensive verification is right in your hands. Examine documents in white, ultraviolet, and infrared lights thanks to the double 48MP/8MP camera.
                    </p>
                    <p className="text-gray-500">
                        Straight, sideways, or upside down, put a document on the device, and the built-in RFID reader extracts data from the RFID chip. Unscramble all kinds of barcodes, including QR codes. Fetch all the details with advanced OCR to eliminate any manual data entry.
                    </p>
                    <p className="text-gray-500">
                        The face matching module compares the portraits on the document and in the chip with your database, as well as the picture taken at the scene. The fingerprint scanner captures the biometrics, and the live finger detection technology doesn’t leave a chance for replicated fingerprints to go through.
                    </p>

                    <p className="text-gray-500">
                        A small device for a forefront user, Regula 7310 can be a part of a bigger security ecosystem. The device comes with an SDK, so document authentication results are available for further use within your existing system.
                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}