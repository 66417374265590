import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 className="text-lg text-gray-500">Mobile Document Reader Regula 7308</h2>
                    <h3 className="text-lg text-gray-500">A Portable Full-Page Passport Reader with a Built-in Computer</h3>
                    <p className="text-lg text-gray-500">
                        The convenient shoulder strap makes it perfect for the day-to-day operations of surveillance agencies.
                    </p>

                    <h3 className="text-lg text-gray-500">A Self-sufficient Portable Reader for Pressing Tasks</h3>
                    <p className="text-lg text-gray-500">
                        Regula 7308 gives you the power to handle ID verification anywhere independently. No need to transfer the data: the built-in computer processes and analyzes it on-board. Hook up any device—a smartphone, tablet, or laptop—to visualize the output.
                    </p>

                    <h3 className="text-lg text-gray-500">Instant Reading of ID Documents</h3>
                    <ul className="text-lg text-gray-500">
                        <li>Passport</li>
                        <li>Driver license</li>
                        <li>ID cards</li>
                        <li>Visas</li>
                        <li>ePassport</li>
                    </ul>

                    <h3 className="text-lg text-gray-500">Highlighted Features</h3>
                    <p className="text-lg text-gray-500">
                        <strong>Fully autonomous device</strong>
                    </p>
                    <ul className="text-lg text-gray-500">
                        <li>A built-in PC with 1.1GHz CPU, 2GB of RAM, and 32GB SSD</li>
                        <li>1 external USB 2.0 port for connecting peripherals (e.g., fingerprint scanner)</li>
                        <li>WLAN</li>
                        <li>Reinforced body with a shoulder strap</li>
                        <li>Two rechargeable batteries</li>
                    </ul>

                    <h3 className="text-lg text-gray-500">Large-scale Capabilities You Can Carry with You</h3>
                    <p className="text-lg text-gray-500">
                        Regula 7308 is the most compact portable reader that can scan and verify the full passport page in one go. Capture images in white, infrared, ultraviolet, and coaxial lights. Fetch data encoded in RFID chips (depending on the configuration). And you can do all of this for 8 hours in a row without recharging.
                    </p>

                    <p className="text-lg text-gray-500">
                        The advanced OCR eliminates the issue of manual data entry: it automatically recognizes and digitizes any text, even engraved and embossed text. As the device comes with an SDK, you can further transfer the gathered data into your existing systems.
                    </p>

                    <p className="text-lg text-gray-500">
                        Extra durable dust- and waterproof hard plastic body. Two rechargeable batteries you can replace without switching off the device. A wide adjustable shoulder strap to comfortably carry the reader throughout the day.
                    </p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                  
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}