import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/RECOVER_LFT-brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="font-bold text-xl mb-4">RECOVER LFT: Latent Fingerprint Technology</h2>
                    <p class="mb-4">RECOVER LFT is a cutting-edge chemical vapor fuming process designed to develop fingerprints on a variety of challenging surfaces, including items exposed to extreme heat (such as discharged bullet casings) and those that have been washed in an attempt to prevent identification. This technology allows for the recovery of fingerprints from items that were previously difficult or impossible to process, showing particularly good results on fired ammunition cases, knives, and contaminated metallic items.</p>

                    <h3 class="font-semibold text-lg mb-2">More Information</h3>
                    <p class="mb-4">RECOVER semi-automates the complex chemistry required to produce consistently high-quality fingerprints on untreated or cyanoacrylate-fumed items of evidence. Designed, engineered, and refined for simplicity and low maintenance, the RECOVER system includes all necessary hardware and software components for effective fingermark development, all within a compact laboratory setup.</p>




                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                
                    <ul class="list-disc pl-6 mb-4">
                        <li><strong>Motorized Lid:</strong> Offers adjustable capacity and is operated via a touchscreen, enabling the use of development chambers of varying sizes.</li>
                        <li><strong>Development Chamber:</strong> Provides 360° visibility and is available in two sizes, allowing users to monitor the development of fingermarks from all angles.</li>
                        <li><strong>Precursor Activation Stage:</strong> Initiates precursor transition with pre-weighed precursor ‘charges’ placed into a temperature-controlled activation stage.</li>
                        <li><strong>Integrated System:</strong> A turnkey laboratory solution that is compact and uncomplicated, taking up minimal workspace and delivered ready-to-use.</li>
                        <li><strong>Touchscreen Interface:</strong> Features intuitive, icon-driven software that simplifies complex chemistry into a straightforward step-by-step process.</li>
                    </ul>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={pdf} download>download</a>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}