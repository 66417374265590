import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from "./Brochures/Civetta_Technical_Document.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div class="product">
                        <h1 class="product-title">Civetta 360° Camera</h1>
                        <p class="product-description">The professional 360° HDRI camera for photogrammetry.</p>

                        <h2 class="product-features-title">Features</h2>
                        <ul class="product-features">
                            <li class="feature">230 Megapixel HDR 360 x 180° digital imaging</li>
                            <li class="feature">Fully automatic capturing each panorama per 40 seconds</li>
                        </ul>

                        <h2 class="product-summary-title">Overview</h2>
                        <p class="product-summary">The Civetta is a fully automated – one button – camera that creates 230 Megapixel full spherical 32-bit High Dynamic Range (HDR) images. As a result, if you are in 360° photogrammetry, there is no better technology you can buy.</p>

                        <h2 class="product-detail-title">Incredible Detail</h2>
                        <p class="product-detail">With a resolution of 230 Megapixels and an exceptional HDR range, the Civetta captures all the essential details.</p>

                        <h2 class="product-speed-title">Fast</h2>
                        <p class="product-speed">It takes only 40 seconds to record the full 360° spherical image. Choose your location, shoot, done.</p>

                        <h2 class="product-simplicity-title">Simple</h2>
                        <p class="product-simplicity">The Civetta is fully calibrated for photogrammetry and takes the best images with only one button.</p>

                        <h2 class="product-resilience-title">Resilient</h2>
                        <p class="product-resilience">Durable, reliable, and stable in harsh environments. It won't let you down or break.</p>

                        <h2 class="product-light-title">Working with Light</h2>
                        <p class="product-light">High Dynamic Range imaging (HDRi) combined with superior low light capability makes the Civetta the perfect tool to capture scenes with varying light conditions. The in-built high-power LED flash can illuminate even the darkest coal mine.</p>

                        <h2 class="product-3d-photogrammetry-title">3D Photogrammetry</h2>
                        <p class="product-3d-photogrammetry">3D photogrammetry is the perfect alternative for laser scanners, offering speed, image quality, ease of operation, and safety.</p>

                        <h2 class="product-360-photogrammetry-title">360° Photogrammetry</h2>
                        <p class="product-360-photogrammetry">The Civetta technology features integrated measurement technology that provides absolute distance, length, angle, or (x,y,z) coordinates, with excellent accuracy of ±2mm in 10m distance.</p>

                        <h2 class="product-accessories-title">Everything You Need</h2>
                        <ul class="product-accessories">
                            <li class="accessory">Integrated LED flash</li>
                            <li class="accessory">Lens protectors</li>
                            <li class="accessory">Water-tight trolley carrying case with foam inlay</li>
                            <li class="accessory">Calibrated tripod</li>
                            <li class="accessory">Remote control</li>
                            <li class="accessory">Battery charger</li>
                            <li class="accessory">Lens cleaner</li>
                            <li class="accessory">Multi-country adapter</li>
                            <li class="accessory">Backup HDD 2 TB</li>
                            <li class="accessory">Software (can be installed on several machines)</li>
                        </ul>
                    </div>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a herf={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}