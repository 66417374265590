import React from "react";
import { Link } from "react-router-dom";

export default function Mission() {
    return (
        <div className="w-full xl:pt-28">
            <div className="w-full items-content justify-center flex bg-[#7a1548] py-7">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 flex">
                    <div className="w-1/2">
                        <span className="text-white text-xl">MISSION & VISION</span>
                    </div>
                    <div className="w-1/2 flex flex-col items-end justify-center">
                        <div className="text-white text-xs">
                            <a href="/">Home</a><span> > </span><a href="/about">About Us</a><span> > </span><span className="text-white text-xs">Mission & Vision</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full items-content justify-center flex">
                <div className="xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12 w-11/12 py-20 items-content justify-center flex">
                    <div className="w-full lg:flex block">
                        <div className="lg:w-1/2 w-full px-4">
                            <img src="../../img/about/vmg.jpg" />
                        </div>
                        <div className="lg:w-1/2 w-full text-gray-500 px-4">
                            <span className="text-3xl">Mission</span><br />
                            <span>Our mission is to advance forensic technology across Qatar and the Middle East by serving as a key distributor for the world's leading vendors in the field. We specialize in providing cutting-edge solutions in every branch of forensics, including:

                                <ul className="list-disc list-inside space-y-2 px-4">
                                    <li>Forensic Science: Offering state-of-the-art analytical tools and technologies.</li>
                                    <li>Digital Forensics: Delivering advanced solutions for data recovery and investigation.</li>
                                    <li>Ballistics and Firearms: Providing precision instruments for firearms and ballistics analysis.</li>
                                    <li>Questioned Document Examination: Supplying tools for accurate document verification and fraud detection.</li>
                                    <li>Crime Scene Investigation: Equipping teams with the latest technology for evidence collection and analysis.</li>
                                    <li>Road Safety: Implementing innovative solutions to enhance traffic management and accident prevention.</li>
                                    <li>Cybersecurity: Ensuring robust protection with cutting-edge cybersecurity measures.</li>
                                </ul>

                                We are dedicated to exceeding expectations by integrating these top-tier technologies to support law enforcement, regulatory compliance, and public safety throughout the region.
                            </span>
                            <br /><br />
                            <span className="text-3xl">Vision</span><br />
                            <span>Our vision is to lead forensic innovation in Qatar and the Middle East, delivering cutting-edge solutions across all forensic branches to enhance security, support law enforcement, and ensure public safety</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}