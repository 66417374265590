import React from 'react'
import { TabGroup } from '@statikly/funk'
import pdf from  "./Brochures/FARO Focus Premium_ Brochure.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div class="product">
                        <h1 class="product-title">FARO Focus Laser Scanners</h1>
                        <p class="product-description">Capture accurate and complete measurements of buildings, facilities, and complex environments with confidence.</p>

                        <h2 class="product-overview-title">Overview</h2>
                        <p class="product-overview">Capturing the measurements of a large building, facility, or crash/crime scene with conventional documentation can take days or weeks, and even then, the data might be incomplete. The FARO Focus Premium and FARO Focus Core create accurate, photorealistic 3D representations of any environment or object in just a few minutes – even in the most extreme outdoor conditions.</p>

                        <h2 class="product-technology-title">Advanced Technology</h2>
                        <p class="product-technology">For best on-site data capture, Focus Premium connects with the new FARO Stream app, bridging FARO hardware to the FARO Sphere cloud environment. Pre-registration scans are fed directly into the cloud, so jobs can be done more efficiently. Users can benefit from Hybrid Reality Capture™, powered by Flash Technology™. The new scan mode for the FARO Focus Premium Laser Scanner is available as an add-on subscription that combines the accuracy of a 3D scan with the speed of a panoramic camera – enabling fast scans in under 30 seconds with colorized 360° images that save up to 50% scanning time.</p>

                        <h2 class="product-benefits-title">Key Benefits</h2>
                        <ul class="product-benefits">
                            <li class="benefit">Never Miss a Measurement: Comprehensive, fast, accurate capturing ensures you never have to go back for missing measurements.</li>
                            <li class="benefit">Reduce Rework and Material Waste: Knowing as-built conditions ensures designs fit the first time.</li>
                            <li class="benefit">Manage Large and Complex Projects with Confidence: Stay updated with FARO software solutions and WebShare for easy 3D data sharing.</li>
                            <li class="benefit">Make Informed Decisions: Equip your team with the information they need to make smarter, faster decisions.</li>
                            <li class="benefit">Adapt As Needed: The integrated accessory bay allows for new sensors or indicators to be added easily.</li>
                        </ul>

                        <h2 class="product-speed-accuracy-title">End the Speed-Accuracy Tradeoff</h2>
                        <p class="product-speed-accuracy">For too long, those in the precision measurement industry have dealt with the speed-accuracy tradeoff. With Hybrid Reality Capture, powered by Flash Technology, this issue is essentially solved, allowing users to anticipate up to 50% scan time savings and reduced per-project costs.</p>

                        <h2 class="product-large-scale-title">Scan Large-Scale Projects with Ease</h2>
                        <p class="product-large-scale">Thanks to enhanced scanning speed, large-volume projects for Architecture, Engineering, Construction, and public safety applications have never been easier, providing scans in less than 30 seconds.</p>

                        <h2 class="product-time-saving-title">Scan More in Less Time</h2>
                        <p class="product-time-saving">With Flash, users can save up to 2.5 days of scanning for projects requiring five scanning days, allowing for improved on-site productivity and better accuracy.</p>

                        <h2 class="product-color-resolution-title">Super-High Color Resolution</h2>
                        <p class="product-color-resolution">The latest color technology enables the Focus Premium to capture scans with up to 266 megapixel color information.</p>

                        <h2 class="product-warranty-title">Two-Year Warranty</h2>
                        <p class="product-warranty">Competitive service maximizes the product's life while reducing total cost of ownership, providing peace of mind for repairs or defective parts.</p>

                        <h2 class="product-software-title">Software Compatibility</h2>
                        <p class="product-software">Process your Focus Laser Scanner point cloud data with any software tool that fits your workflow, including FARO software solutions and third-party software such as Autodesk® ReCap™.</p>

                        <h2 class="product-speed-title">Up to 50% Faster Scan Times</h2>
                        <p class="product-speed">With the Flash Technology add-on and required PanoCam, a typical scan takes less than 30 seconds, saving up to 2.5 days of scanning for projects requiring five scanning days.</p>

                        <h2 class="product-price-performance-title">Best Price-Performance Standard on the Market</h2>
                        <p class="product-price-performance">Focus Core broadens the range of potential applications and is fully integrated into FARO’s workflow solutions.</p>

                        <h2 class="product-efficiency-title">Exceptional Capturing Efficiency</h2>
                        <p class="product-efficiency">Enjoy scan speeds up to one minute per scan and greater data management efficiency when paired with Stream and Sphere.</p>


                    </div>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2 class="product-features-title">FARO Focus Premium and Core Features</h2>
                    <ul class="product-features">
                        <li class="feature">Up to 350m scanning range for superior area coverage per scan position.</li>
                        <li class="feature">Smartphone-enabled remote-control capabilities.</li>
                        <li class="feature">Improved wireless workflow with more stable and faster Wi-Fi operation.</li>
                        <li class="feature">On-site registration for faster project completion.</li>
                        <li class="feature">Seamless connectivity with Stream and Sphere.</li>
                        <li class="feature">Users can create projects, change scanner settings, and manage image resolution easily.</li>
                        <li class="feature">Rugged construction and housing for durability.</li>
                        <li class="feature">Integrated high-speed SSD data storage for maximum scan capacity.</li>
                        <li class="feature">Flash Technology enabled for fastest scans with greatest accuracy.</li>
                        <li class="feature">Hybrid Reality Capture scan mode available as a subscription through the FARO Stream App.</li>
                        <li class="feature">Proprietary smart upscaling algorithms ideal for large-volume scans.</li>
                    </ul>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <div className="text-gray-500 text-lg">
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                     
                    </div>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a her={pdf} download>download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}