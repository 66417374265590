import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <h2 className='text-gray-500 '>First Line ID Authentication with Border Control Quality</h2>
                    <h3 className='text-gray-500 '>Document Reader Regula 70X4M</h3>
                    <p className='text-gray-500 '>A full-page passport reader for quick, hassle-free reading and verification of identity documents and automated data entry.</p>

                    <h4 className='text-gray-500 '>Installed at 80 Borders</h4>
                    <p className='text-gray-500 '>A simple and reliable model, Regula Document Reader 70X4M is capable of revolutionizing daily operations at border controls, banks, immigration services, hotels, casinos, and other businesses requiring ID checks.</p>

                    <h4 className='text-gray-500 '>Technologies Used</h4>
                    <ul className='text-gray-500 '>
                        <li><strong>Automatic authenticity verification:</strong> Document verification with 26 checks.</li>
                        <li><strong>Automatic document type identification:</strong> Verify data from 12,000+ international documents using AI and ML.</li>
                        <li><strong>Optical Character Recognition (OCR):</strong> Convert typed or printed text into digital data.</li>
                        <li><strong>MRZ reading:</strong> Scanning and recognition of data from machine-readable zones.</li>
                        <li><strong>RFID reading:</strong> Verify documents equipped with Radio Frequency Identification (RFID) chips.</li>
                        <li><strong>Barcode reading:</strong> Recognize all kinds of barcodes to accelerate operations.</li>
                        <li><strong>Processing graphic fields:</strong> Automatic search and crop of signature, fingerprint, and document holder's portrait to display as separate images.</li>
                        <li><strong>Text data comparison:</strong> Cross-checking of text data from MRZ, Barcode, Visual zone, and RFID.</li>
                        <li><strong>Face Matching:</strong> Cross-checking of traveler's photos from Visual zone, RFID, and external camera.</li>
                        <li><strong>Integration of IRS:</strong> Optional integration of the Information Reference System allows automatic display of reference images of security features of the examined document.</li>
                        <li><strong>Smart UV:</strong> Compensation of external light during image capture in ultraviolet light.</li>
                        <li><strong>Elimination of glare:</strong> Remove reflection from laminate and holograms in white and IR light.</li>
                    </ul>

                    <h4 className='text-gray-500 '>Functionality Honed to Perfection</h4>
                    <p className='text-gray-500 '>Regula 70X4M is a compact, reliable device that reads a full passport page and automatically inspects every security feature in white, infrared, ultraviolet, and coaxial lights.</p>

                    <p className='text-gray-500 '>The advanced OCR, trained specifically for identity documents, recognizes engraved and embossed text. Smart algorithms instantly read barcodes and machine-readable zones. All models come with RFID chip reading, and certain versions also include a Smart card reader.</p>

                    <p className='text-gray-500 '>Using the SDK that comes with every Regula device, you can integrate it into your systems and customize your data handling workflow. Additionally, the IRS database integration in the SDK provides reference images of authentic documents under various light sources for comparison.</p>

                    <p className='text-gray-500 '>Regula 70X4M is constructed from durable plastic with no moving parts and connects to a PC via USB. An optional flip-top cover protects against external light, ensuring accurate scanning and character recognition.</p>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}