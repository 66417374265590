import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./Brochure/"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className=' text-gray-500 '>

                        <p>

                            Get an access to add extra documents and banknotes.
                        </p>
                        The software has a multilingual user-friendly interface which allows the user to add to the database new documents, as well as necessary information on documents and security features.
                        Regula conducts online training on program operation.




                        <ul>
                            <li>Main
                                <ul>
                                    <li>Providing access to basic settings of the program: language, selection of database, etc.</li>
                                    <li>Navigation panel for scaling images, displaying/hiding database, displaying/hiding hints, etc.</li>
                                </ul>
                            </li>
                            <li>Operations with the country list
                                <ul>
                                    <li>Adding a new country: the country name, a three-letter country code, and add its flag image</li>
                                    <li>Editing country information</li>
                                    <li>Deleting a country</li>
                                </ul>
                            </li>
                            <li>Operations with documents
                                <ul>
                                    <li>Adding a new document: selection of the database, document type, adding document description, adding document name</li>
                                    <li>Deleting a document</li>
                                    <li>Document export</li>
                                </ul>
                            </li>
                            <li>Document description
                                <ul>
                                    <li>Information sections: Description, Additional</li>
                                    <li>Description of the document type: book block, card, form, paper, sticker, visa</li>
                                    <li>Viewing document description</li>
                                    <li>Adding a comment to a document</li>
                                </ul>
                            </li>
                            <li>Operations with characteristics
                                <ul>
                                    <li>Adding, editing, deleting a new characteristic</li>
                                </ul>
                            </li>
                            <li>Directory system for storing images. File formats
                                <ul>
                                    <li>When you add a new document, a directory system is created for it</li>
                                </ul>
                            </li>
                            <li>Operations with pages
                                <ul>
                                    <li>Adding and deleting a page</li>
                                </ul>
                            </li>
                            <li>Operations with fragments
                                <ul>
                                    <li>Adding a new fragment</li>
                                    <li>Adding a dynamic fragment .GIF</li>
                                    <li>Editing a fragment</li>
                                    <li>Adding a fragment frame to the image in general view</li>
                                    <li>Deleting a frame from an image in general view</li>
                                    <li>Deleting a fragment</li>
                                </ul>
                            </li>
                        </ul>

                    </p>


                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='text-gray-500 text-lg'>

                        <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>


                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}