import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochure/VSC9000.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p>
                        Equipped with a high-performance optical and illumination system, VSC9000 enables the examiner to conduct multi-spectral examinations of documents in microscopic detail to authenticate legitimate documents, reveal sophisticated counterfeits and to identify evidence of tampering or manipulation with unparalleled accuracy.
                        Ensuring the authenticity of critical documents is an increasingly complex challenge.
                        From the controlled environment of a police laboratory to the fast-moving world of international border security, VSC9000 provides a comprehensive forensic-level solution to the interrogation and analysis of all documents types.
                        VSC9000 provides a complete forensic toolset to ‘dissect’ questioned documents, for the inspection of every fine detail from paper and ink composition to the presence of 3rd level embedded security features. Beyond the physical, VSC9000 can reveal and analyse every layer of digitally embedded data (including Biometric e-Chip, MRZ, IPI, RFID etc.) to provide a comprehensive 360o interrogation of a document.

                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='text-gray-500 text-lg'>
                        Camera

                    </p>
                    <p>
                        Primary: Colour/IR high resolution, live 12MP, spectral range 350-1100nm.
                        Secondary: A4/Letter overview image, live 12MP, Spectral range 350-1100nm.

                    </p>
                    <p className='text-gray-500 text-lg'>
                        Standard Illumination

                    </p>
                    <p>
                        <ul>
                            <li>Incident Flood
                                <ul>
                                    <li>Visible, IR, UV-A, UV-B & UV-C (LED)</li>
                                </ul>
                            </li>
                            <li>Transmitted
                                <ul>
                                    <li>Flood: UV, Visible, IR (LED)</li>
                                    <li>Spot: 400-1000nm (LED)</li>
                                </ul>
                            </li>
                            <li>Oblique/Side
                                <ul>
                                    <li>Visible and IR (LED)</li>
                                </ul>
                            </li>
                            <li>Variable Bandpass Spot
                                <ul>
                                    <li>16x Low Pass Filters, 16x High Pass Filters (250w)</li>
                                </ul>
                            </li>
                            <li>Narrow Bandpass Spot
                                <ul>
                                    <li>400-1000nm: 1nm steps (100W)</li>
                                </ul>
                            </li>
                        </ul>



                    </p>
                    <p className='text-gray-500 text-lg'>
                        Specialist Illumination

                    </p>
                    <p>
                        <ul>
                            <li>OVD – Two-axis Visible array (LED)</li>
                            <li>Coaxial – Segmented array (LED)</li>
                            <li>Anti-Stokes – IR 980nm (LED)</li>
                            <li>3D Imaging – Visible and IR (LED)</li>
                            <li>Phosphorescence – Pulsed UV (LED)</li>
                            <li>Polarised – Visible Polarised (LED)</li>
                        </ul>


                    </p>
                    <p className='text-gray-500 text-lg'>
                        Microspectrometer

                    </p>
                    <p>
                        Absorption, reflectance, transmission and fluorescence spectra –
                        Spectral Range: 400-1000nm, Spatial Resolution: 2nm.



                    </p>
                    <p className='text-gray-500 text-lg'>
                        XY-Stage

                    </p>
                    <p>
                        Motorised stage – Movement range of at lest 60 x 45mm, step resolution 0.01mm (10μm).



                    </p>
                    <h2>Software</h2>

                    <h3>Advanced Imaging Modes</h3>
                    <ul>
                        <li>Super Resolution Imaging (SRi)</li>
                        <li>3D Imaging</li>
                        <li>HyperSpectral Imaging (HSi) requires spectrometer</li>
                        <li>High Dynamic Range Imaging (HDR)</li>
                        <li>Focus Stacking/Focal Plane Merging</li>
                    </ul>

                    <h3>Image Comparison & Analysis</h3>
                    <ul>
                        <li>Live image mode with complete control of camera settings and functions</li>
                        <li>Document comparison mode (“Live vs Stored” images or “Stored vs Stored”)</li>
                        <li>Digital enhancement mode</li>
                        <li>Annotation and calibrated measurements</li>
                        <li>Digital zoom up to 16x</li>
                        <li>Colour isolation and false-colour imaging</li>
                        <li>Machine Identification Code (MIC) / Counterfeit Protection System (CPS) toolbox</li>
                    </ul>

                    <h3>Security Feature Authentication</h3>
                    <ul>
                        <li>Automated MRZ recognition (AI Assist)</li>
                        <li>IPI/Digital IPI Decoder</li>
                        <li>LetterScreen ++ Decoder</li>
                        <li>1D and 2D Barcode reader</li>
                        <li>Extended Access Control (EAC)</li>
                    </ul>

                    <h3>Users</h3>
                    <ul>
                        <li>Application specific workspaces</li>
                        <li>Interactive tutorial mode</li>
                        <li>Remote Examination</li>
                    </ul>

                    <h3>Calibration & System Performance</h3>
                    <ul>
                        <li>Automated diagnostic routines</li>
                        <li>Lamp life monitoring</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}