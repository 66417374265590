import React from 'react'
import { TabGroup } from '@statikly/funk'
import ESDA2 from "./Brochure/VSC-900.pdf"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='font-semibold'>

                        The complete solution for hybrid document interrogations
                    </p>
                    <p>
                        Introducing a new VSC workstation for the hybrid physical and digital inspection of travel and identity documents, designed to meet the complex demands of immigration/border security applications.
                        The first in a new generation of hybrid VSC document examination systems, the VSC 900 provides examiners with a comprehensive suite of traditional and advanced tools for the interrogation and verification of passports, ID cards, visas, residence permits, and other questioned documents.
                        A natural evolution of the VSC range, the VSC900 has been refined to provide the ideal balance between size and performance when examining a wide range of documents.
                        Combining multi-spectral illumination and unrivalled image quality, for optical inspection of documents, with forensic-level access to eChip and biometric data, for digital authentication, the VSC900 provides a complete solution for modern hybrid document interrogations.

                    </p>
                    <p className='font-semibold'>

                        Operational benefits
                    </p>
                    <p>
                        <p>

                            •	For the comprehensive examination of physical and digital document security features.
                        </p>
                        <p>

                            •	Authenticate genuine documents and identify counterfeits, forgeries, and pseudo documents.
                        </p>
                        <p>

                            •	Build up a forensic profile of fraudulent documents.
                        </p>

                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='text-gray-500 text-lg'>

                        <ul>
                            <li>Specifications
                                <ul>
                                    <li>High-Resolution 8MP camera</li>
                                    <li>Large (A4) document viewing area</li>
                                    <li>158x magnification</li>
                                    <li>Full LED illumination</li>
                                    <li>Fully-licensed data decoders</li>
                                    <li>Motorised XY positioning stage</li>
                                    <li>Choice of embedded or external PC</li>
                                </ul>
                            </li>
                        </ul>


                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">
                    <ul>
                        <li>Applications
                            <ul>
                                <li>Border Security & Immigration</li>
                                <li>High-Security Locations</li>
                                <li>Card Grading & Authentication</li>
                            </ul>
                        </li>
                        <li>For the hybrid physical/digital examinations of:
                            <ul>
                                <li>Biometric ePassports</li>
                                <li>National ID cards and Identity Documents</li>
                                <li>Visas and Residence Permits</li>
                                <li>Breeder Documents</li>
                                <li>Other Certificates & Permits</li>
                            </ul>
                        </li>
                    </ul>
                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                   

                    <a href={ESDA2} download >download</a>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}