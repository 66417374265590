import React from 'react'
import { TabGroup } from '@statikly/funk'
// import ESDA2 from "./"

export default function MyTabs() {
    return (
        <div className="w-full flex flex-col">
            <TabGroup numTabs={5} direction={TabGroup.direction.HORIZONTAL}>
                <TabGroup.TabList className="text-sm font-bold">
                    <TabGroup.Tab
                        index={0}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        ABOUT
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        style={{ backgournd: "#000" }}
                        index={1}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-stone-50 border-b-2 hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        FEATURES
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={2}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        APPLICATION
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={3}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        DOWNLOADS
                    </TabGroup.Tab>
                    <TabGroup.Tab
                        index={4}
                        className="h-10 px-3 transition-colors duration-300"
                        activeClassName="border-gray-300 border-2 border-b-white hover:text-blue-800"
                        inactiveClassName="text-gray-400 hover:text-blue-800 border-b"
                    >
                        VIDEOS
                    </TabGroup.Tab>

                </TabGroup.TabList>
                <TabGroup.TabPanel
                    index={0}
                    className="pt-10 transition-all transform h-64 text-gray-500 text-lg h-auto"
                    activeClassName="opacity-100 duration-500"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <h2>IRS Frontline Documents System</h2>
                    <p>The IRS Frontline Documents System contains images and detailed descriptions of passports, ID cards and other travel documents, driver’s licenses, vehicle registration certificates, and other vehicle-related documents.</p>
                    <p>Borderless access to more than 4,000 document templates from 222 countries and territories.</p>

                    <h3>Information Reference System</h3>
                    <p>The Frontline Document System by Regula contains images of documents, their security features, and printing techniques with detailed descriptions:</p>
                    <ul>
                        <li>Document pages in white, IR 870 nm, UV 254, 313, and 365 nm spectral ranges</li>
                        <li>Security features and techniques such as microprinting, intaglio, watermarks, holograms, etc.</li>
                        <li>Glossary with descriptions and illustrations of security features and techniques</li>
                    </ul>

                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={1}
                    className="pt-10 transition-all transform flex flex-col h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className='text-gray-500 '>
                        <h2>Features</h2>
                        <ul>
                            <li>High resolution images of documents, banknotes, their security features and techniques</li>
                            <li>Possibility to select the amount of information provided</li>
                            <li>GIF-animation of holograms and OVI-elements</li>
                            <li>Smart search</li>
                            <li>You can add notes and comments to existing documents</li>
                            <li>Multilingual interface</li>
                            <li>Online training on program operation</li>
                            <li>Automatic update (requires Internet connection)</li>
                            <li>Integration with Regula Forensic Studio</li>
                        </ul>

                        <h2>Document Types</h2>
                        <ul>
                            <li>3,085 passports</li>
                            <li>205 visas</li>
                            <li>958 vehicle related documents</li>
                            <li>222 countries</li>
                        </ul>

                        <h2>IRS Versions</h2>
                        <ul>
                            <li>Brief (33,283 images) — includes only document images in different spectral ranges for basic control</li>
                            <li>Express (104,434 images) — includes some basic security features and document images for express control</li>
                            <li>Forensic (163,774 images) — includes security features and images for advanced document examination</li>
                        </ul>


                    </p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={2}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >

                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={3}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                    {/* <a href={ESDA2} download >download</a> */}
                </TabGroup.TabPanel>
                <TabGroup.TabPanel
                    index={4}
                    className="pt-10 transition-all transform h-64 h-auto"
                    activeClassName="opacity-100 duration-500 translate-x-0"
                    inactiveClassName="absolute opacity-0 -translate-x-2"
                >
                    <p className="text-lg text-gray-500">Please contact us and request for additional information.</p>
                </TabGroup.TabPanel>
            </TabGroup>
        </div>
    )
}